import { getUserSessionStorage } from '@/composables/useSessions'
import {
  NavigationGuard,
  RouteLocationNormalized,
  NavigationGuardNext
} from 'vue-router'
import { basketState } from '@/composables/useBasket'

export const authenticate: NavigationGuard = (_to, _from, next) => {
  if (!getUserSessionStorage()) return next({ name: 'Home' })

  return next()
}

export const checkBasketPresence: NavigationGuard = (
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (!basketState.basketId) {
    next({ name: 'Home' })
  } else next()
}
