<template>
  <svg
    width="25"
    height="22"
    viewBox="0 0 25 22"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 8.25V2.75C25 1.22375 23.875 0 22.5 0H2.5C1.125 0 0.0125 1.22375 0.0125 2.75V8.25C1.3875 8.25 2.5 9.4875 2.5 11C2.5 12.5125 1.3875 13.75 0 13.75V19.25C0 20.7625 1.125 22 2.5 22H22.5C23.875 22 25 20.7625 25 19.25V13.75C23.625 13.75 22.5 12.5125 22.5 11C22.5 9.4875 23.625 8.25 25 8.25ZM13.75 18.5625H11.25V15.8125H13.75V18.5625ZM13.75 12.375H11.25V9.625H13.75V12.375ZM13.75 6.1875H11.25V3.4375H13.75V6.1875Z"
    />
  </svg>
</template>
