import { ref, readonly, watchEffect } from 'vue'
import { useRouter } from 'vue-router'

interface SnackbarState {
  message: string;
  type?: 'danger' | 'success';
}

const snackbarState = ref<SnackbarState>()
export const setSnackbar = (message: string, type?: 'danger' | 'success') => {
  closeSnackbar()

  setTimeout(() => {
    snackbarState.value = { message, type }
  }, 10)
}

const closeSnackbar = () => { snackbarState.value = undefined }

export default function useSnackbar () {
  const router = useRouter()

  router.beforeEach(() => closeSnackbar())

  watchEffect(() => {
    if (!snackbarState.value) return

    setTimeout(() => {
      document.getElementById('snackbar')?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
      document.getElementById('dismiss')?.focus()
    }, 10)
  })

  return {
    setSnackbar,
    closeSnackbar,
    snackbarState: readonly(snackbarState)
  }
}
