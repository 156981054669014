<template>
  <svg viewBox="0 0 14 12" fill="black" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.5968 8.90766L1.4693 5.78016L0.404297 6.83766L4.5968 11.0302L13.5968 2.03016L12.5393 0.972656L4.5968 8.90766Z"
      />
  </svg>
</template>
<style lang="scss" scoped>
svg.highlight {
  fill: white;
}
</style>
