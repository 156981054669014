<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="#C2002F"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 2.997c3.232 0 3.614.012 4.89.07 1.18.055 1.822.251 2.248.417.565.22.968.482 1.392.905.423.423.686.826.905 1.39.166.427.363 1.067.417 2.246.059 1.276.07 1.658.07 4.887 0 3.23-.011 3.612-.07 4.887-.054 1.18-.25 1.82-.417 2.246-.22.564-.481.967-.905 1.39a3.75 3.75 0 01-1.392.905c-.426.165-1.067.363-2.247.416-1.276.058-1.66.07-4.891.07-3.232 0-3.615-.012-4.891-.07-1.18-.053-1.82-.25-2.247-.416a3.744 3.744 0 01-1.392-.905 3.748 3.748 0 01-.905-1.39c-.166-.427-.363-1.067-.417-2.246-.059-1.275-.071-1.657-.071-4.887 0-3.23.012-3.611.07-4.887.055-1.179.251-1.82.417-2.245.22-.565.482-.968.906-1.39a3.752 3.752 0 011.392-.906c.426-.165 1.067-.362 2.247-.416 1.276-.059 1.659-.07 4.89-.07zm0-2.18c-3.287 0-3.7.015-4.99.073-1.288.06-2.168.264-2.938.563a5.935 5.935 0 00-2.144 1.395A5.929 5.929 0 001.532 4.99c-.3.769-.504 1.648-.562 2.935-.06 1.29-.074 1.702-.074 4.987 0 3.284.014 3.696.073 4.986.06 1.287.263 2.166.563 2.936a5.93 5.93 0 001.395 2.142 5.934 5.934 0 002.144 1.394c.77.3 1.65.504 2.938.562 1.291.06 1.703.073 4.99.073s3.7-.014 4.99-.073c1.289-.059 2.169-.263 2.939-.562a5.934 5.934 0 002.143-1.394 5.93 5.93 0 001.396-2.142c.3-.77.504-1.65.563-2.936.058-1.29.072-1.702.072-4.986 0-3.285-.014-3.697-.072-4.987-.06-1.287-.264-2.166-.563-2.935a5.929 5.929 0 00-1.395-2.142 5.935 5.935 0 00-2.144-1.395c-.77-.299-1.65-.504-2.939-.562C16.7.831 16.288.818 13 .818z"
    />
    <path
      d="M13 6.701a6.213 6.213 0 00-6.215 6.21c0 3.43 2.783 6.21 6.215 6.21a6.213 6.213 0 006.215-6.21c0-3.43-2.782-6.21-6.215-6.21zm0 10.242a4.033 4.033 0 01-4.034-4.032A4.033 4.033 0 0113 8.88a4.033 4.033 0 014.035 4.031A4.033 4.033 0 0113 16.943zM20.913 6.455a1.452 1.452 0 11-2.904-.001 1.452 1.452 0 012.904.001z"
    />
  </svg>
</template>
