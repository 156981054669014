
import { defineComponent } from 'vue'
import ArrowDropdown from '@/components/vectors/ArrowDropdown.vue'

export default defineComponent({
  components: { ArrowDropdown },
  emits: ['update:modelValue', 'blur'],
  props: {
    modelValue: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    error: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})
