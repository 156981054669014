
import { defineComponent } from 'vue'
import ModalLink from '@/components/CMSModalLink.vue'
import InfoIcon from '@/components/vectors/Info.vue'
import QuantitySelect from '@/components/QuantitySelect.vue'

export default defineComponent({
  components: { ModalLink, InfoIcon, QuantitySelect },
  props: {
    modelValue: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    caption: {
      type: String,
      required: true
    },
    infoPath: String
  },
  emits: ['update:modelValue'],
  data: () => ({
    min: 0,
    max: 4
  })
})
