
import { defineComponent } from 'vue'
import GenericError from '@/components/GenericError.vue'
import Spinner from '@/components/Spinner.vue'

export default defineComponent({
  components: { GenericError, Spinner },
  emits: ['select'],
  props: {
    expanded: {
      type: Boolean,
      default: false
    },
    submenu: Object,
    error: Object
  }
})
