<template>
  <div
    class="ckeditor-box flex flex-col flex-wrap justify-center"
    v-html="finalHtml"
  ></div>
</template>
<script lang="ts">
import { defineComponent, ref, nextTick } from 'vue'
import { convertCkEditorData } from '@/helpers/ckEditor'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fbAsyncInit: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FB: any
  }
}

window.fbAsyncInit = function () {
  window?.FB?.init({
    appId: process.env.VUE_APP_FACEBOOK_APP_ID,
    xfbml: true,
    version: 'v15.0',
    target: '_top'
  })
}

export default defineComponent({
  name: 'CkeditorModule',
  props: {
    html: {
      type: String,
      required: true
    }
  },
  setup (props) {
    try {
      const finalHtml = ref('')
      const convertAndRenderData = () => {
        let { html } = props
        if (html.includes('%')) {
          try {
            html = decodeURIComponent(html)
          } catch (error) {
          }
        }
        const { tmpElement, containFacebook } = convertCkEditorData(html)
        finalHtml.value = tmpElement.innerHTML
        nextTick(() => {
          if (containFacebook) {
            window.fbAsyncInit()
          }
        })
      }
      convertAndRenderData()
      return {
        finalHtml
      }
    } catch (error) {
      return {
        finalHtml: ''
      }
    }
  }
})
</script>
<style>
/* stylelint-disable */
.twitter-tweet iframe {
  width: 100% !important;
  max-width: 450px !important;
  margin: 0 auto;
}
.youtube-box iframe {
  width: 100% !important;
  max-width: 600px !important;
  margin: 0 auto;
}
.fb-post span, .fb-video span {
  margin: 0 auto !important;
  width: 100% !important;
  max-width: 500px !important;
}
.fb-post span iframe, .fb-post span iframe {
  width: 100% !important;
}

.ckeditor-box ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
.ckeditor-box ::-webkit-scrollbar-thumb {
  background: rgb(157, 157, 157);
  border-radius: 30px;
}
.ckeditor-box ::-webkit-scrollbar-thumb:hover {
  background: rgb(99, 99, 99);
  border-radius: 30px;
}
/* stylelint-enable */
</style>
<style scoped>
:deep(div) {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
</style>
