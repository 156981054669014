import { companyRoutes } from './company'
import { planRoutes } from './plan'
import { servicesRoutes } from './services'

export const menuRoutes = [
  ...companyRoutes,
  ...servicesRoutes,
  ...planRoutes,
  {
    path: '/search-tickets',
    name: 'SearchTickets',
    component: () =>
      import(/* webpackChunkName: "tickets" */ '@/views/SearchTickets.vue')
  },
  {
    path: '/realtime',
    name: 'Realtime',
    component: () =>
      import(/* webpackChunkName: "realtime" */ '@/views/Realtime.vue')
  }
]
