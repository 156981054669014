<template>
  <section :class="{ [slug]: true, error: error, loading: !page }" :key="slug">
    <template v-if="page">
      <teleport to="head">
        <title>{{ page?.metadataPageTitle || '' }}</title>
        <meta name="description" :content="page.metadataMetaDescription" />
        <meta name="keywords" :content="page.metadataMetaKeywords" />
      </teleport>
      <PageHeader
        :heading="page.title"
        :subheading="page.subtitle"
        v-if="$route.meta.showHeader !== false && page.title !== ''"
      />
      <div class="card">
        <img
          class="lead-image"
          v-if="$route.meta.showHeader !== false && page.image?.url"
          :src="page.image.url"
          :alt="page.image.altText"
        />
        <div class="cms-rich-text">
          <div class="body ckeditor" v-if="page.body || page.bodyCkeditor">
            <RichTextRender
              :html="page.body || ''"
              :linkedItems="page.modularContent"
              v-if="page.body !== '' && page.body !== '<p><br></p>'" />
            <CkeditorModule :html="page.bodyCkeditor || ''" v-if="page && page.bodyCkeditor" />
          </div>
        </div>
      </div>
    </template>
    <GenericError v-else-if="error" />
    <Spinner v-else />
  </section>
</template>

<script lang="ts">
import { defineComponent, ref, watchEffect, computed } from 'vue'
import Spinner from '@/components/Spinner.vue'
import GenericError from '@/components/GenericError.vue'
import PageHeader from '@/components/PageHeader.vue'
import useTitle from '@/composables/useTitle'
import router from '@/router'
import CkeditorModule from '@/components/CkeditorModule.vue'
import { getPage, Page } from '@/kentico-api/submenus.api'
import { AxiosResponse } from 'axios'
import { onBeforeRouteUpdate } from 'vue-router'
import { useHead } from '@vueuse/head'
import RichTextRender from '@/components/kontent/RichTextRender.vue'

export default defineComponent({
  components: { Spinner, GenericError, PageHeader, CkeditorModule, RichTextRender },
  props: {
    slug: {
      type: String,
      required: true
    },
    redirectOnError: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  // eslint-disable-next-line max-lines-per-function
  setup (props) {
    try {
      const { setTitle } = useTitle()
      const page = ref()
      const error = ref()

      const loadPageContent = (slug: string) => {
        getPage(slug)
          .then(({ data }: AxiosResponse<Page>) => {
            if (!Object.keys(data).length) {
              if (props.redirectOnError) {
                router.replace({ name: 'Not Found' })
                return
              }
              error.value = true
              return
            }

            page.value = data
          })
          .catch(e => {
            error.value = e
          })
      }

      loadPageContent(props.slug)

      onBeforeRouteUpdate(to => { loadPageContent(to.params.slug as string) })

      watchEffect(() => setTitle(page.value?.title))

      useHead({
        meta: [
          {
            name: 'og:description',
            content: computed(() => page.value?.openGraphOgDescription || ''),
            key: 'ogDescription'
          },
          {
            name: 'og:title',
            content: computed(() => page.value?.openGraphOgTitle || ''),
            key: 'ogTitle'
          },
          {
            name: 'og:url',
            content: `${process.env.VUE_APP_SITE_URL}${props.slug}`,
            key: 'ogUrl'
          },
          {
            name: 'og:image',
            itemprop: 'image',
            content: computed(() => page.value && page.value.openGraphOgImage?.length > 0
              ? page.value.openGraphOgImage[0].url
              : `${process.env.VUE_APP_SITE_URL}images/expressway-image.jpeg`),
            key: 'ogImage'
          },
          {
            name: 'og:locale',
            content: computed(() => page.value?.openGraphOgLocale || 'en_IE'),
            key: 'ogLocale'
          }
        ]
      })
      return { page, error }
    } catch (error) {
      return { error: true }
    }
  }
})
</script>
<style lang="scss" scoped>
section.error,
section.loading {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.card {
  padding: 0;
  line-height: 1.25rem;
  font-size: 0.875rem;
}

img { max-width: 100%; }

.body {
  padding: 0 36px;

  :deep(img) {
    max-width: 100%;
  }

  :deep(figure) {
    margin: 0;
  }
}

@media only screen and (min-width: 789px) {
  .card { margin-bottom: 83px; }

  .body {
    padding: 60px 84px;
  }
}

@media only screen and (max-width: 790px) {
  .card { border-radius: 0; }
}

</style>
