import { camelize } from '@/camelize'
import { AxiosResponse } from 'axios'
import { ImageItem, Item, Element } from '.'

let linkedItems: { [x: string]: Item }
const itemMap = new Map()
const memoizeItem = (codename: string, generateItem: () => Record<string, unknown>) => {
  if (itemMap.has(codename)) return itemMap.get(codename)

  itemMap.set(codename, generateItem())
  return itemMap.get(codename)
}

const transformDefaultItem = (
  requestType: string,
  { elements }: Item,
  nestingDenylist?: string[],
  treatAsArray?: string[]
) => Object.keys(elements).reduce((elementObj, key) => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  elementObj[key] = transformElement(requestType, elements[key], nestingDenylist, treatAsArray)
  return elementObj
}, {} as { [x: string]: unknown })

const transformImageItem = ({
  elements: {
    image: {
      value: [image]
    },
    alternate_text: { value: altText }
  }
}: ImageItem) => ({ ...image, altText })

const transformItems = (requestType: string, items: Item[], nestingDenylist?: string[]) => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  if (items.length === 1) return transformItem(requestType, items[0], nestingDenylist)

  return items.reduce((itemsHash: { [index: string]: Item }, item: Item) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    itemsHash[item.system.codename] = transformItem(requestType, item, nestingDenylist)
    return itemsHash
  }, {})
}

const transformItem = (requestType: string, item: Item, nestingDenylist?: string[]) => {
  if (!item) return

  const {
    system: { codename, type }
  } = item
  // eslint-disable-next-line complexity
  return memoizeItem(codename, () => {
    switch (type) {
      case 'image':
        return transformImageItem(item as ImageItem)
        break
      case 'destination':
        return transformDefaultItem(requestType, item, ['Explore Destinations'], ['Routes To'])
        break
      case 'routes_page':
        return transformDefaultItem(requestType, item, [], ['routes list'])
        break
      case 'route_description':
        if (requestType === 'destination') {
          return transformDefaultItem(requestType, item,
            ['Route -> Route Description', 'More routes', 'timetables'], [])
        }
        return { ...transformDefaultItem(requestType, item, [], ['More routes', 'timetables']) }

        break
      default:
        return transformDefaultItem(requestType, item, nestingDenylist)
        break
    }
  })
}
// eslint-disable-next-line complexity
const transformElement = (
  requestType: string,
  element: Element,
  nestingDenylist?: string[],
  treatAsArray?: string[]
) => {
  if (element.type === 'modular_content') {
    if (treatAsArray?.includes(element.name)) {
      return element.value.map(key => ({
        key: camelize(key),
        ...transformItem(requestType, linkedItems[key], nestingDenylist)
      }))
    } else if (nestingDenylist?.includes(element.name)) {
      return element.value.map(key => camelize(key))
    }
    if (element.value.length === 1) {
      return transformItem(requestType, linkedItems[element.value[0]], nestingDenylist)
    }

    return element.value.reduce((item: { [x: string]: unknown }, itemKey) => {
      item[linkedItems[itemKey].system.codename] = transformItem(
        requestType,
        linkedItems[itemKey],
        nestingDenylist
      )
      return item
    }, {})
  } else return element.value
}

const linkedDestinations = (requestType: string) => {
  const filteredItems = Object.values(linkedItems).filter(
    item => item.system.type === 'destination'
  )
  if (filteredItems.length === 0) return null

  return {
    linkedDestinations: transformItems(requestType, filteredItems, ['Explore Destinations'])
  }
}
// eslint-disable-next-line complexity
export default function responseTransformer (
  response: AxiosResponse
): AxiosResponse {
  let requestType = ''
  if (response.config.params) {
    requestType = response.config.params['system.type'] || ''
  }

  let responseString = JSON.stringify(response)
  if (process.env.VUE_APP_KENTICO_PREVIEW_API_KEY) {
    responseString = responseString.replaceAll(
      'https://preview-assets-eu-01.kc-usercontent.com', process.env.VUE_APP_KENTICO_ASSETS_URL
    )
  } else {
    responseString = responseString.replaceAll(
      'https://assets-eu-01.kc-usercontent.com', process.env.VUE_APP_KENTICO_ASSETS_URL
    )
  }
  response = JSON.parse(responseString)
  const {
    data: { item, items, modular_content: modularContent }
  } = response
  itemMap.clear()
  linkedItems = modularContent
  if (item) {
    return {
      ...response,
      data: {
        ...transformItem(requestType, item),
        ...linkedDestinations(requestType),
        modularContent
      }
    }
  }
  return {
    ...response,
    data: {
      ...transformItems(requestType, items),
      ...linkedDestinations(requestType),
      modularContent
    }
  }
}
