<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="8" fill="#555555" />
    <path
      d="M11.2985 5.79946L10.6827 5.18359L8.24107 7.6252L5.79946 5.18359L5.18359 5.79946L7.6252 8.24107L5.18359 10.6827L5.79946 11.2985L8.24107 8.85693L10.6827 11.2985L11.2985 10.6827L8.85693 8.24107L11.2985 5.79946Z"
      fill="white"
    />
  </svg>
</template>
