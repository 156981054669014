import { signIn, validateTravelAccountCredentials } from '@/expressway-api/auth.api'
import { User, UserSession } from '@/models/User'
import { computed, onMounted, onUnmounted, ref, watch, readonly } from 'vue'

const USER_SESSION_KEY = 'USER_SESSION_KEY'

export const getUserSessionStorage = (): UserSession | undefined => {
  const sessionString = localStorage.getItem(USER_SESSION_KEY)
  return sessionString ? JSON.parse(sessionString) : null
}

export const setUserSessionStorage = (userSession?: UserSession) => {
  if (userSession) {
    localStorage.setItem(USER_SESSION_KEY, JSON.stringify(userSession))
  } else localStorage.removeItem(USER_SESSION_KEY)
}

const userSession = ref<UserSession | undefined>(getUserSessionStorage())

const storageListener = (event: StorageEvent) => {
  if (event.key === USER_SESSION_KEY) {
    userSession.value = getUserSessionStorage()
  }
}

export default function useSessions () {
  const isLoggedIn = computed(() => !!userSession.value)
  const logIn = (user: User) =>
    signIn(user).then(response => {
      userSession.value = { ...response.data, Username: user.Username }
      return userSession.value
    })
  const logOut = () => {
    localStorage.clear()
    userSession.value = undefined
  }

  watch(userSession, () => {
    setUserSessionStorage(userSession.value)
  })

  onMounted(() => {
    window.addEventListener('storage', storageListener)
  })
  onUnmounted(() => {
    window.removeEventListener('storage', storageListener)
  })

  const verifyPassword = async (user: User) =>
    await validateTravelAccountCredentials(user).then(() => true)
      .catch(() => false)

  return {
    verifyPassword,
    userSession: readonly(userSession),
    isLoggedIn,
    logIn,
    logOut
  }
}
