
import { defineComponent, onMounted, onUnmounted, ref, watch } from 'vue'
import NavItem from '@/components/Navbars/NavItem.vue'
import NavSubmenu from '@/components/Navbars/NavSubmenu.vue'
import useNavMenu from '@/composables/useNavMenu'
import { useRouter } from 'vue-router'

import Hamburger from '@/components/vectors/Hamburger.vue'
import Close from '@/components/vectors/Close.vue'
import Plan from '@/components/vectors/Plan.vue'
import Ticket from '@/components/vectors/Ticket.vue'
import Clock from '@/components/vectors/Clock.vue'
import BusIcon from '@/components/vectors/BusIcon.vue'
import Person from '@/components/vectors/Person.vue'
import useSessions from '@/composables/useSessions'

export default defineComponent({
  components: {
    NavItem,
    NavSubmenu,
    Hamburger,
    Close,
    Plan,
    Ticket,
    Clock,
    BusIcon,
    Person
  },
  setup () {
    const { isLoggedIn } = useSessions()
    const showNav = ref(false)
    const toggleNav = () => {
      showNav.value = !showNav.value
    }
    const navMenu = useNavMenu()
    const { activeSubmenu } = navMenu
    const router = useRouter()

    const handleClickOutside = ({ target }: Event) => {
      if (
        target instanceof Element &&
        target.closest('header nav, .toggle-nav')
      ) { return }

      showNav.value = false
      activeSubmenu.value = undefined
    }
    onMounted(() => document.addEventListener('click', handleClickOutside))
    onUnmounted(() => document.removeEventListener('click', handleClickOutside))

    router.beforeEach(() => {
      showNav.value = false
    })

    watch(showNav, () => {
      activeSubmenu.value = undefined
    })

    return {
      isLoggedIn,
      showNav,
      toggleNav,
      ...navMenu,
      activeSubmenu
    }
  }
})
