import { httpClient, httpClientBasic } from './httpClient'
import { AxiosResponse } from 'axios'
import { User, UserDetail, UserSession } from '@/models/User'

const routeChangePassword = 'travel-accounts/change-password'
const routeTravelAccount = 'travel-account'
const routeTravelAccounts = 'travel-accounts'
const signInRoute = 'auth'
const resetPasswordRoute = 'reset-password'
const setPasswordRoute = 'set-password'
const acceptTandCsRoute = '/travel-accounts/accept-terms'

const signIn = (user: User): Promise<AxiosResponse<UserSession>> =>
  httpClientBasic.post<UserSession>(`${routeTravelAccounts}/${signInRoute}`, {
    ...user
  })
const validateTravelAccountCredentials = (user: User): Promise<AxiosResponse<UserSession>> =>
  httpClientBasic.post<UserSession>(`${routeTravelAccounts}/${signInRoute}`, {
    ...user
  })
const createAccount = async (
  email: string,
  isSendingInfoAllowed: boolean
): Promise<AxiosResponse> =>
  await httpClient.post(
    `${routeTravelAccounts}`,
    { Email: email, IsSendingInfoAllowed: isSendingInfoAllowed }
  )

const DeleteAccount = async (): Promise<AxiosResponse> =>
  await httpClient.delete(
    `${routeTravelAccount}`
  )

const resetPassword = async (email: string): Promise<AxiosResponse> =>
  await httpClient.post(`${routeTravelAccounts}/${resetPasswordRoute}`, {
    Email: email
  })

const setPassword = async (
  resetHash: string,
  newPassword: string
): Promise<AxiosResponse> =>
  await httpClient.post(`${routeTravelAccounts}/${setPasswordRoute}`, {
    ResetHash: resetHash,
    NewPassword: newPassword
  })

const getBuyerDetails = async (): Promise<UserDetail> =>
  (await httpClient.get<UserDetail>(routeTravelAccount)).data

const acceptTandCs = async (): Promise<AxiosResponse<UserDetail>> =>
  httpClient.post<UserDetail>(acceptTandCsRoute)

const updateBuyerDetails = async (buyer: UserDetail): Promise<AxiosResponse> =>
  (await httpClient.put<AxiosResponse>(routeTravelAccount, buyer)).data

const updateBuyerPassword = async (password: string): Promise<AxiosResponse> =>
  (await httpClient.put<AxiosResponse>(routeChangePassword, { Password: password })).data

export {
  signIn,
  resetPassword,
  setPassword,
  createAccount,
  DeleteAccount,
  getBuyerDetails,
  acceptTandCs,
  updateBuyerDetails,
  updateBuyerPassword,
  validateTravelAccountCredentials
}
