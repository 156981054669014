
import { defineComponent } from 'vue'
import CheckboxIcon from '@/components/vectors/Checkbox.vue'

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true
    },
    label: String,
    modelValue: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: { CheckboxIcon },
  emits: ['update:modelValue'],
  methods: {
    changeValue () {
      if (!this.disabled) {
        this.$emit('update:modelValue', !this.modelValue)
      }
    }
  }
})
