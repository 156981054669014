<template>
  <svg
    width="13"
    height="7"
    viewBox="0 0 13 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 0.75L6.5 6.25L1 0.75" stroke="#262626" />
  </svg>
</template>
