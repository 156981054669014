<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="0.9375"
      width="24"
      height="24"
      rx="4"
      fill="white"
      stroke="black"
    />
    <rect
      x="1"
      y="0.9375"
      width="24"
      height="24"
      rx="4"
      fill="white"
      stroke="#9D9D9D"
    />
    <path
      class="checkmark"
      d="M7 12.9375L10.3333 16.9375L19.6667 8.9375"
      stroke="#368F89"
      stroke-width="3"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg[role='checkbox'][aria-checked='false'] .checkmark {
  display: none;
}
</style>
