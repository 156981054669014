
import { defineComponent, ref, watchEffect, computed } from 'vue'
import Spinner from '@/components/Spinner.vue'
import GenericError from '@/components/GenericError.vue'
import PageHeader from '@/components/PageHeader.vue'
import useTitle from '@/composables/useTitle'
import router from '@/router'
import CkeditorModule from '@/components/CkeditorModule.vue'
import { getPage, Page } from '@/kentico-api/submenus.api'
import { AxiosResponse } from 'axios'
import { onBeforeRouteUpdate } from 'vue-router'
import { useHead } from '@vueuse/head'
import RichTextRender from '@/components/kontent/RichTextRender.vue'

export default defineComponent({
  components: { Spinner, GenericError, PageHeader, CkeditorModule, RichTextRender },
  props: {
    slug: {
      type: String,
      required: true
    },
    redirectOnError: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  // eslint-disable-next-line max-lines-per-function
  setup (props) {
    try {
      const { setTitle } = useTitle()
      const page = ref()
      const error = ref()

      const loadPageContent = (slug: string) => {
        getPage(slug)
          .then(({ data }: AxiosResponse<Page>) => {
            if (!Object.keys(data).length) {
              if (props.redirectOnError) {
                router.replace({ name: 'Not Found' })
                return
              }
              error.value = true
              return
            }

            page.value = data
          })
          .catch(e => {
            error.value = e
          })
      }

      loadPageContent(props.slug)

      onBeforeRouteUpdate(to => { loadPageContent(to.params.slug as string) })

      watchEffect(() => setTitle(page.value?.title))

      useHead({
        meta: [
          {
            name: 'og:description',
            content: computed(() => page.value?.openGraphOgDescription || ''),
            key: 'ogDescription'
          },
          {
            name: 'og:title',
            content: computed(() => page.value?.openGraphOgTitle || ''),
            key: 'ogTitle'
          },
          {
            name: 'og:url',
            content: `${process.env.VUE_APP_SITE_URL}${props.slug}`,
            key: 'ogUrl'
          },
          {
            name: 'og:image',
            itemprop: 'image',
            content: computed(() => page.value && page.value.openGraphOgImage?.length > 0
              ? page.value.openGraphOgImage[0].url
              : `${process.env.VUE_APP_SITE_URL}images/expressway-image.jpeg`),
            key: 'ogImage'
          },
          {
            name: 'og:locale',
            content: computed(() => page.value?.openGraphOgLocale || 'en_IE'),
            key: 'ogLocale'
          }
        ]
      })
      return { page, error }
    } catch (error) {
      return { error: true }
    }
  }
})
