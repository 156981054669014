import {
  addTripToBasket,
  addTravelPassesToBasket,
  createBasket,
  hookBasket,
  deleteBasket,
  renewBasket,
  payBasketWithZeroPayment
} from '@/expressway-api/baskets.api'
import { Trip } from '@/models/Trip'
import { TravelPassPayload } from '@/models/TravelPass'
import router from '@/router'
import { RouteLocationRaw } from 'vue-router'
import { AxiosError, AxiosResponse } from 'axios'
import { ref, toRefs } from 'vue'
import useSnackbar from './useSnackbar'
import useLocalStorage from './useLocalStorage'
import useTimeout from './useTimeout'

export const basketState = useLocalStorage(
  'basketState', {
    basketId: '',
    showExpiryWarning: false,
    showBootMessage: false,
    canRenew: true
  }
)

// eslint-disable-next-line max-lines-per-function
export default function useBasket (onError?: (error: Error) => void) {
  const isSetting = ref(false)
  const error = ref()
  const { setSnackbar } = useSnackbar()

  const {
    setTimer: setWarningTimer,
    clearTimer: clearWarningTimer
  } = useTimeout('basketIdWarning', () => {
    basketState.showExpiryWarning = true
  })

  const { setTimer: setBootTimer, clearTimer: clearBootTimer } = useTimeout('basketId', () => {
    router.push({ name: 'Home' })
      .then(() => { basketState.showBootMessage = true })
  })

  const clearLocalBasket = () => {
    basketState.showExpiryWarning = false
    basketState.showBootMessage = false
    basketState.canRenew = true
    clearWarningTimer()
    clearBootTimer()
    basketState.basketId = ''
  }

  const clearPaidBasket = () => {
    clearLocalBasket()
  }

  const clearBasket = () => {
    if (basketState.basketId?.length) deleteBasket(basketState.basketId)
    clearLocalBasket()
  }

  const payWithZeroPaymentMethod = (rootTo: RouteLocationRaw) => {
    payBasketWithZeroPayment(basketState.basketId)
      .then(() => {
        router.push(rootTo)
      })
      .catch((e: AxiosError) => {
        router.push({ name: 'Home' }).then(() => setSnackbar(e.message))
      })
  }

  const renewBasketExpiry = () => {
    renewBasket(basketState.basketId)
      .then(({ data }: AxiosResponse) => {
        setWarningTimer(data.ExpiresInMilliseconds * 0.7)
        setBootTimer(data.ExpiresInMilliseconds)
        basketState.showExpiryWarning = false
        basketState.canRenew = data.CanRenew
      })
      .catch((e: AxiosError) => {
        router.push({ name: 'Home' }).then(() => setSnackbar(e.message))
      })
  }

  const handleError = (e: Error) => {
    if (onError) onError(e)
    isSetting.value = false
    error.value = e
    document.getElementById('alert-banner')?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
  }

  const getBasket = (basketId: string) => {
    if (!basketId) throw Error('Cannot continue without a basket')
    hookBasket(basketId)
      .then(basket => {
        setWarningTimer(basket.ExpiresInMilliseconds * 0.7)
        setBootTimer(basket.ExpiresInMilliseconds)
        basketState.basketId = basket.ShoppingBasketId
      })
      .catch(handleError)
  }

  const confirmBasket = (trip?: Trip) => {
    if (!trip) throw Error('Cannot continue without a trip to add to basket')

    isSetting.value = true
    createBasket()
      .then(basket => {
        setWarningTimer(basket.ExpiresInMilliseconds * 0.7)
        setBootTimer(basket.ExpiresInMilliseconds)
        basketState.basketId = basket.ShoppingBasketId
        return addTripToBasket(basket.ShoppingBasketId, trip)
      })
      .then(() => router.push({ name: 'Add Extras' }))
      .catch(handleError)
  }

  const confirmTravelPassBasket = (travelPasses?: TravelPassPayload[]) => {
    if (!travelPasses || travelPasses.length === 0) {
      throw Error('Cannot continue without a travelPass to add to basket')
    }

    isSetting.value = true
    return createBasket()
      .then(basket => {
        setWarningTimer(basket.ExpiresInMilliseconds * 0.7)
        setBootTimer(basket.ExpiresInMilliseconds)
        basketState.basketId = basket.ShoppingBasketId
        return addTravelPassesToBasket(basket.ShoppingBasketId, travelPasses)
      })
      .then(() => router.push({ name: 'Confirm TravelPass' }))
      .catch(handleError)
  }

  return {
    isSetting,
    error,
    ...toRefs(basketState),
    confirmBasket,
    getBasket,
    renewBasketExpiry,
    clearBasket,
    confirmTravelPassBasket,
    payWithZeroPaymentMethod,
    clearPaidBasket,
    clearLocalBasket
  }
}
