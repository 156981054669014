
import { defineComponent, ref } from 'vue'
import Modal from '@/components/Modal.vue'
import Spinner from '@/components/Spinner.vue'
import GenericError from '@/components/GenericError.vue'
import kenticoClient from '@/kentico-api/httpClient'

export default defineComponent({
  components: { Modal, Spinner, GenericError },
  props: {
    codename: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const modalContent = ref()
    const loading = ref(true)
    const error = ref()

    kenticoClient
      .get(`/${props.codename}`)
      .then(({ data }) => {
        modalContent.value = data
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })

    return {
      showModal: ref(false),
      modalContent,
      loading,
      error
    }
  }
})
