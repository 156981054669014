<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="#7B7B7B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9868 11H12.7997L17.9065 16L16.38 17.491L11.263 12.5V11.708L10.9804 11.434C9.81631 12.408 8.30823 13 6.65478 13C2.97929 13 0 10.09 0 6.5C0 2.91 2.97929 0 6.65478 0C10.3303 0 13.3096 2.91 13.3096 6.5C13.3096 8.115 12.7024 9.589 11.7042 10.726L11.9868 11ZM2.04761 6.5C2.04761 8.986 4.10957 11 6.65477 11C9.19895 11 11.2619 8.986 11.2619 6.5C11.2619 4.015 9.19895 2 6.65477 2C4.10957 2 2.04761 4.015 2.04761 6.5Z"
    />
  </svg>
</template>
