
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import Combobox from '@/components/Combobox.vue'
import Wheelchair from '@/components/vectors/Wheelchair.vue'
import PinIcon from '@/components/vectors/PinIcon.vue'
import AlertIcon from '@/components/vectors/AlertIcon.vue'
import ClearButton from '@/components/vectors/ClearButton.vue'
import Spinner from '@/components/Spinner.vue'
import { BusStop } from '@/models/BusStop'

export default defineComponent({
  name: 'StopSeach',
  components: {
    Combobox,
    PinIcon,
    AlertIcon,
    ClearButton,
    Spinner,
    Wheelchair
  },
  emits: ['update:modelValue', 'close'],
  props: {
    id: {
      type: String,
      default: () => 'stop'
    },
    stops: {
      type: Array as PropType<BusStop[]>,
      default: () => []
    },
    error: {
      type: Boolean,
      default: () => null
    },
    showStopId: {
      type: Boolean,
      default: true
    },
    countryFilter: {
      type: Number as PropType<number | null>,
      default: null
    },
    modelValue: Object as PropType<BusStop>,
    label: String
  },
  setup (props, { emit }) {
    const combobox = ref()
    const searchTerm = ref(props.modelValue?.BusStopName || '')
    const focus = () => {
      setTimeout(() => {
        combobox.value?.input.focus()
      }, 0)
    }
    const removeSelection = () => {
      searchTerm.value = ''
      emit('update:modelValue', undefined)
    }
    const filteredStops = computed(() =>
      props.stops.filter(stop => stop.CountryId !== props.countryFilter)
    )

    watch(() => props.modelValue, val => {
      if (val === undefined) {
        searchTerm.value = ''
      } else {
        searchTerm.value = val.BusStopName
      }
    })

    return {
      removeSelection,
      focus,
      combobox,
      searchTerm,
      filteredStops
    }
  }
})
