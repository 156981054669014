/* eslint-disable no-unused-vars */
// There is something wrong with eslint thinking these are unused
export const enum FareClass {
  Adult = 'BONUS_SCHEME_GROUP.ADULT',
  Child = 'BONUS_SCHEME_GROUP.CHILDREN',
  YoungAdult = 'BONUS_SCHEME_GROUP.THE_YOUNG',
  Student = 'BONUS_SCHEME_GROUP.STUDENT',
  SeatReservation = 'BONUS_SCHEME_GROUP.SEAT_RESERVATION',
  DSP = 'BONUS_SCHEME_GROUP.DSP'
}

export const FareMap: { [x: string]: string } = {
  adult: 'BONUS_SCHEME_GROUP.ADULT',
  child: 'BONUS_SCHEME_GROUP.CHILDREN',
  youngAdult: 'BONUS_SCHEME_GROUP.THE_YOUNG',
  student: 'BONUS_SCHEME_GROUP.STUDENT',
  seatReservation: 'BONUS_SCHEME_GROUP.SEAT_RESERVATION',
  dsp: 'BONUS_SCHEME_GROUP.DSP'
}
export const FareMapInverse: { [x: string]: string } = {
  'BONUS_SCHEME_GROUP.ADULT': 'adult',
  'BONUS_SCHEME_GROUP.CHILDREN': 'child',
  'BONUS_SCHEME_GROUP.THE_YOUNG': 'youngAdult',
  'BONUS_SCHEME_GROUP.STUDENT': 'student',
  'BONUS_SCHEME_GROUP.SEAT_RESERVATION': 'seatReservation',
  'BONUS_SCHEME_GROUP.DSP': 'dsp'
}

interface fareInfo {
  name?: string;
  caption: string;
  infoPath?: string;
}
export interface faresComplete {
  [key: string]: fareInfo | undefined;
  adult?: fareInfo;
  child?: fareInfo;
  youngAdult?: fareInfo;
  student?: fareInfo;
  dsp?: fareInfo;
  seatReservation?: fareInfo;
}
export const passengerTypes: faresComplete = {
  adult: {
    caption: '16+ years'
  },
  child: {
    caption: '5 -15 years'
  },
  student: {
    name: 'Young Adult / Student',
    caption: 'With valid with YA / Student Leap Card',
    infoPath: 'passenger_info___student'
  },
  dsp: {
    name: 'Seat Only Reservation',
    caption: 'Free Travel Scheme',
    infoPath: 'passenger_info___seat_only_reservation_free_travel'
  },
  seatReservation: {
    name: 'Seat Only Reservation',
    caption: 'Valid Ticket Required',
    infoPath: 'passenger_info___seat_only_reservation_valid_ticke'
  }
}

export const fareStrings: { [x: string]: string } = {
  [FareClass.Adult]: 'Adult',
  [FareClass.Child]: 'Child',
  [FareClass.YoungAdult]: 'Young Adult / Student',
  [FareClass.Student]: 'Young Adult / Student',
  [FareClass.SeatReservation]: 'Reserved Seat',
  [FareClass.DSP]: 'Free Travel Seat'
}
export interface Fares {
  adult: number;
  child: number;
  youngAdult: number;
  student: number;
  dsp: number;
  seatReservation: number;
  [x: string]: number | undefined;
}
