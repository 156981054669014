
import { defineComponent, ref, nextTick } from 'vue'
import { convertCkEditorData } from '@/helpers/ckEditor'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fbAsyncInit: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FB: any
  }
}

window.fbAsyncInit = function () {
  window?.FB?.init({
    appId: process.env.VUE_APP_FACEBOOK_APP_ID,
    xfbml: true,
    version: 'v15.0',
    target: '_top'
  })
}

export default defineComponent({
  name: 'CkeditorModule',
  props: {
    html: {
      type: String,
      required: true
    }
  },
  setup (props) {
    try {
      const finalHtml = ref('')
      const convertAndRenderData = () => {
        let { html } = props
        if (html.includes('%')) {
          try {
            html = decodeURIComponent(html)
          } catch (error) {
          }
        }
        const { tmpElement, containFacebook } = convertCkEditorData(html)
        finalHtml.value = tmpElement.innerHTML
        nextTick(() => {
          if (containFacebook) {
            window.fbAsyncInit()
          }
        })
      }
      convertAndRenderData()
      return {
        finalHtml
      }
    } catch (error) {
      return {
        finalHtml: ''
      }
    }
  }
})
