// eslint-disable-next-line max-lines-per-function
export const convertCkEditorData = (html: string) => {
  const tmpElement = document.createElement('div')
  tmpElement.innerHTML = html
  let containFacebook = false
  const embedMediaList = tmpElement.querySelectorAll('[data-oembed-url]')
  // eslint-disable-next-line complexity
  embedMediaList.forEach(embedMedia => {
    const convertedMedia = embedMedia as HTMLElement
    const embedUrl = convertedMedia.dataset.oembedUrl
    if (/youtu/.test(embedUrl || '')) {
      while (embedMedia.firstChild) {
        embedMedia.removeChild(embedMedia.firstChild)
      }
      const iframe = document.createElement('iframe')
      iframe.setAttribute('src',
        embedUrl?.replace('/watch', '/embed')
          .replace('youtu.be', 'youtube.com/embed')
          .replace('?v=', '/') || '')
      iframe.setAttribute('allowfullscreen', 'true')
      iframe.setAttribute('X-Frame-Options', '*')
      embedMedia.appendChild(iframe)
    } else if (/twitter/.test(embedUrl || '')) {
      while (embedMedia.firstChild) {
        embedMedia.removeChild(embedMedia.firstChild)
      }
      const div = document.createElement('div')
      div.innerHTML = `<blockquote class="twitter-tweet">
                  <a href="${embedUrl}"></a>
                </blockquote>`
      embedMedia.appendChild(div)
      const script = document.createElement('script')
      script.async = true
      script.src = 'https://platform.twitter.com/widgets.js'
      document.head.appendChild(script)
    } else if (
      ((/facebook/.test(embedUrl || '')) && (/videos/.test(embedUrl || ''))) ||
      (/fb.wa/.test(embedUrl || '')) ||
      (/facebook.com\/watch/.test(embedUrl || ''))) {
      while (embedMedia.firstChild) {
        embedMedia.removeChild(embedMedia.firstChild)
      }
      const script = document.createElement('script')
      script.async = true
      // script.crossOrigin = 'anonymous'
      script.defer = true
      script.src =
        'https://connect.facebook.net/en_US/sdk.js'
      document.body.appendChild(script)
      let fbWidth = 500
      if (document.body.clientWidth < 500) {
        fbWidth = document.body.clientWidth
      }
      const div = document.createElement('div')
      div.classList.add('fb-video')
      div.setAttribute('data-href', embedUrl || '')
      div.setAttribute('data-width', String(fbWidth))
      embedMedia.appendChild(div)
      containFacebook = true
    } else if (
      ((/facebook/.test(embedUrl || '')) && (/posts/.test(embedUrl || ''))) ||
      (/facebook/.test(embedUrl || ''))) {
      while (embedMedia.firstChild) {
        embedMedia.removeChild(embedMedia.firstChild)
      }
      const script = document.createElement('script')
      // script.crossOrigin = 'anonymous'
      script.async = true
      script.defer = true
      script.src =
        'https://connect.facebook.net/en_US/sdk.js'
      document.body.appendChild(script)
      let fbWidth = 500
      if (document.body.clientWidth < 500) {
        fbWidth = document.body.clientWidth
      }
      const div = document.createElement('div')
      div.classList.add('fb-post')
      div.setAttribute('data-href', embedUrl || '')
      div.setAttribute('data-width', String(fbWidth))
      embedMedia.appendChild(div)
      containFacebook = true
    }
  })
  const matchesTwitter = tmpElement.querySelectorAll(
    '[data-oembed-url*="twitter"]'
  )
  matchesTwitter.forEach(twitterBox => {
    (twitterBox as HTMLElement).style.width = 'auto'
    ;(twitterBox.firstElementChild as HTMLElement).style.margin = '0 auto'
    const child = twitterBox.firstElementChild as HTMLElement
    ;(child.firstElementChild as HTMLElement).style.margin = '0 auto'
  })

  const matchesFb = tmpElement.querySelectorAll(
    '[data-oembed-url*="facebook"]'
  )
  matchesFb.forEach(facebook => {
    (facebook as HTMLElement).style.width = 'auto'
    ;(facebook as HTMLElement).style.textAlign = 'center';
    (facebook.firstElementChild as HTMLElement).style.margin = '0 auto'
  })

  const matchesYoutube = tmpElement.querySelectorAll(
    '[data-oembed-url*="youtu"]'
  )
  matchesYoutube.forEach(youtube => {
    (youtube as HTMLElement).style.height = '315px'
    ;(youtube as HTMLElement).style.width = '100%'
    ;(youtube as HTMLElement).style.maxWidth = '600px'
    ;(youtube as HTMLElement).classList.add('youtube-box')
    ;(youtube.firstElementChild as HTMLElement).style.margin = '0 auto'
    ;(youtube.firstElementChild as HTMLElement).style.height = '315px'
    ;(youtube.firstElementChild as HTMLElement).style.width = '100%'
    ;(youtube.firstElementChild as HTMLElement).style.maxWidth = '600px'
    let ytWidth = 560
    if (document.body.clientWidth < 560) {
      ytWidth = document.body.clientWidth
    }
    (
      youtube.firstElementChild as HTMLElement
    ).style.width = `${ytWidth}px`
  })

  const other = tmpElement.querySelectorAll('[data-oembed-url]')
  other.forEach(otherBox => {
    (otherBox as HTMLElement).style.minHeight = '170px'
    if (otherBox.firstElementChild?.firstElementChild) {
      const child = otherBox.firstElementChild
        ?.firstElementChild as HTMLElement
      child.style.minHeight = '170px'
    }
  })

  return {
    tmpElement,
    containFacebook
  }
}
