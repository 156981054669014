import { RouteLocationNormalized } from 'vue-router'
import { decodeFares } from '@/helpers/fares'
import { FareMap } from '@/models/FareClass'
import { checkBasketPresence } from '@/router/navigationGuards'

export const bookingsRoutes = [
  {
    path: '/journey-search',
    name: 'Journey Search',
    props: ({ params }: RouteLocationNormalized) => {
      if (typeof params.props !== 'string') return {}

      return { ...JSON.parse(params.props) }
    },
    meta: {
      hideBookTicketButton: true
    },
    component: () =>
      import(/* webpackChunkName: "bookings" */ '../views/JourneySearch.vue')
  },
  {
    path: '/select-journey',
    name: 'Select Journey',
    props: ({ query }: RouteLocationNormalized) => ({
      ...query,
      destinationId: Number(query.destinationId),
      originId: Number(query.originId),
      isOutbound: query.isOutbound !== 'false',
      oneWay: query.oneWay === 'true',
      fares: decodeFares(query.fares as string),
      wheelchair: query.wheelchair === 'true',
      wheelchairPassengerFareClass: FareMap[query.wheelchairPassengerFareClass as string] ||
        query.wheelchairPassengerFareClass
    }),
    meta: { showFooter: false, hideBookTicketButton: true },
    component: () =>
      import(/* webpackChunkName: "bookings" */ '../views/SelectJourney.vue')
  },
  {
    path: '/add-extras',
    name: 'Add Extras',
    props: ({ query }: RouteLocationNormalized) => ({
      basketId: query.basketId
    }),
    meta: { showFooter: false, hideBookTicketButton: true },
    component: () =>
      import(/* webpackChunkName: "bookings" */ '../views/AddExtras.vue')
  },
  {
    path: '/confirm-journey',
    name: 'Confirm Journey',
    meta: { showFooter: false, hideBookTicketButton: true },
    component: () =>
      import(/* webpackChunkName: "bookings" */ '../views/ConfirmJourney.vue')
  },
  {
    path: '/trip-buyer-details',
    name: 'Trip Buyer Details',
    meta: { showFooter: false, hideBookTicketButton: true },
    beforeEnter: checkBasketPresence,
    component: () =>
      import(/* webpackChunkName: "bookings" */ '../views/TripBuyerDetails.vue')
  },
  {
    path: '/passenger-details',
    name: 'Passenger Details',
    meta: { showFooter: false, hideBookTicketButton: true },
    beforeEnter: checkBasketPresence,
    component: () =>
      import(/* webpackChunkName: "bookings" */ '../views/PassengerDetails.vue')
  },
  {
    path: '/trip-payment-details',
    name: 'Trip Payment Details',
    meta: { showFooter: false, hideBookTicketButton: true },
    beforeEnter: checkBasketPresence,
    component: () =>
      import(/* webpackChunkName: "bookings" */ '../views/TripPaymentDetails.vue')
  },
  {
    path: '/trip-payment-confirmation/:basketId',
    name: 'Trip Payment Confirmation',
    props: true,
    meta: { showFooter: false, hideBookTicketButton: true },
    component: () =>
      import(/* webpackChunkName: "bookings" */ '../views/TripPaymentConfirmation.vue')
  },
  {
    path: '/booking-refund/:bookingId',
    name: 'BookingRefund',
    meta: { showFooter: false },
    component: () =>
      import(/* webpackChunkName: "bookings" */ '../views/RefundBooking.vue')
  },
  {
    path: '/booking-details/:id',
    name: 'BookingInfo',
    meta: { showFooter: false },
    component: () =>
      import(/* webpackChunkName: "bookings" */ '../views/MyBookingInfo.vue')
  }
]
