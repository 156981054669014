
import { defineComponent, onMounted, onUnmounted } from 'vue'
import Close from '@/components/vectors/Close.vue'

export default defineComponent({
  inheritAttrs: false,
  emits: ['close'],
  props: {
    closeable: {
      type: Boolean,
      default: true
    }
  },
  components: { Close },
  setup (props, { emit }) {
    let scroll: number

    onMounted(() => {
      scroll = window.scrollY
      document.body.style.position = 'fixed'
      document.body.style.top = `-${scroll}px`
    })

    onUnmounted(() => {
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, scroll)
    })

    return {
      close: () => {
        if (props.closeable) emit('close')
      }
    }
  }
})
