import { RouteLocationNormalized } from 'vue-router'
import { decodeFares } from '@/helpers/fares'
import { checkBasketPresence } from '@/router/navigationGuards'

export const travelPassRoutes = [
  {
    path: '/travel-pass',
    name: 'TravelPass',
    meta: { showFooter: false, hideBookTicketButton: true },
    component: () =>
      import(/* webpackChunkName: "travelPass" */ '../views/TravelPass.vue')
  },
  {
    path: '/buy-travel-pass',
    name: 'Buy TravelPass',
    props: ({ query }: RouteLocationNormalized) => ({
      ...query,
      destinationId: Number(query.destinationId) || null,
      originId: Number(query.originId) || null,
      passengers: decodeFares(query.passengers as string || '')
    }),
    meta: { showFooter: false, hideBookTicketButton: true },
    component: () =>
      import(/* webpackChunkName: "travelPass" */ '../views/BuyTravelPass.vue')
  },
  {
    path: '/select-travel-pass-date',
    name: 'Select TravelPass Date',
    props: ({ query }: RouteLocationNormalized) => ({
      ...query,
      destinationId: Number(query.destinationId),
      originId: Number(query.originId),
      fares: decodeFares(query.fares as string)
    }),
    meta: { showFooter: false },
    component: () =>
      import(/* webpackChunkName: "travelPass" */ '../views/SelectTravelPassDate.vue')
  },
  {
    path: '/confirm-travel-pass',
    name: 'Confirm TravelPass',
    props: ({ query }: RouteLocationNormalized) => ({
      basketId: query.basketId
    }),
    meta: { showFooter: false },
    component: () =>
      import(/* webpackChunkName: "travelPass" */ '../views/ConfirmTravelPass.vue')
  },
  {
    path: '/travel-pass-buyer-details',
    name: 'TravelPass Buyer Details',
    meta: { showFooter: false },
    beforeEnter: checkBasketPresence,
    component: () =>
      import(/* webpackChunkName: "travelPass" */ '../views/TravelPassBuyerDetails.vue')
  },
  {
    path: '/travel-pass-payment-details',
    name: 'TravelPass Payment Details',
    meta: { showFooter: false },
    beforeEnter: checkBasketPresence,
    component: () =>
      import(/* webpackChunkName: "travelPass" */ '../views/TravelPassPaymentDetails.vue')
  },
  {
    path: '/travel-pass-payment-confirmation/:basketId',
    name: 'TravelPass Payment Confirmation',
    props: true,
    meta: { showFooter: false },
    component: () =>
      import(/* webpackChunkName: "travelPass" */ '../views/TravelPassPaymentConfirmation.vue')
  },
  {
    path: '/use-travel-pass',
    name: 'Use TravelPass',
    meta: { showFooter: false },
    component: () =>
      import(/* webpackChunkName: "travelPass" */ '../views/UseTravelPass.vue')
  },
  {
    path: '/travel-pass-journey-search',
    name: 'TravelPass Journey Search',
    props: ({ query }: RouteLocationNormalized) => ({
      ...query,
      travelPassNumber: Number(query.travelPass)
    }),
    meta: { showFooter: false },
    component: () =>
      import(/* webpackChunkName: "travelPass" */ '../views/TravelPassJourneySearch.vue')
  },
  {
    path: '/travel-pass-select-journey',
    name: 'TravelPass Select Journey',
    props: ({ query }: RouteLocationNormalized) => ({
      ...query,
      originId: Number(query.originId),
      destinationId: Number(query.destinationId),
      isOutbound: query.isOutbound !== 'false',
      isPartOfRoundTrip: query.isPartOfRoundTrip === 'true',
      travelPassNumber: Number(query.travelPassNumber),
      fares: decodeFares(query.fares as string)
    }),
    meta: { showFooter: false },
    component: () =>
      import(/* webpackChunkName: "bookings" */ '../views/TravelPassSelectJourney.vue')
  }
]
