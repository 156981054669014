import { tabbable } from 'tabbable'

const onTab = (event: KeyboardEvent, el: HTMLElement) => {
  if (event.key !== 'Tab' || !event.target) return

  const tabbableElements = tabbable(el)
  const focusedIndex = tabbableElements.indexOf(event.target as HTMLElement)
  const nextIndex = event.shiftKey
    ? (focusedIndex + tabbableElements.length - 1) % tabbableElements.length
    : (focusedIndex + 1) % tabbableElements.length
  tabbableElements[nextIndex]?.focus()
  event.preventDefault()
}

let outerFocus: Element | null

export default {
  beforeMount (el: HTMLElement) {
    outerFocus = document.activeElement
    el.addEventListener('keydown', event => {
      if (event.key !== 'Tab' || !event.target) return

      onTab(event, el)
    })
  },
  mounted (el: HTMLElement) {
    tabbable(el)[0]?.focus()
  },
  unmounted () {
    if (outerFocus instanceof HTMLElement) outerFocus.focus()
  }
}
