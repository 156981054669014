
import NavItem from '@/components/Navbars/NavItem.vue'
import FooterNavSubmenu from '@/components/Navbars/FooterNavSubmenu.vue'
import useNavMenu from '@/composables/useNavMenu'
import Twitter from '@/components/vectors/Twitter.vue'
import Instagram from '@/components/vectors/Instagram.vue'
import Facebook from '@/components/vectors/Facebook.vue'
import { defineComponent } from 'vue'
import useSessions from '@/composables/useSessions'

export default defineComponent({
  components: {
    NavItem,
    FooterNavSubmenu,
    Twitter,
    Instagram,
    Facebook
  },
  setup () {
    const { isLoggedIn } = useSessions()

    return {
      isLoggedIn,
      ...useNavMenu()
    }
  }
})
