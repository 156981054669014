<template>
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4444 0L19.2667 0.0366666L13.3333 2.56667L6.66667 0L0.4 2.32222C0.166667 2.40778 0 2.62778 0 2.90889V21.3889C0 21.7311 0.244444 22 0.555556 22L0.733333 21.9633L6.66667 19.4333L13.3333 22L19.6 19.6778C19.8333 19.5922 20 19.3722 20 19.0911V0.611111C20 0.268889 19.7556 0 19.4444 0ZM13.3333 19.5556L6.66667 16.9767V2.44444L13.3333 5.02333V19.5556Z"
    />
  </svg>
</template>
