import { createApp } from 'vue'
import App from './App.vue'
import trapFocus from './directives/trap-focus'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import router from './router'
import { createHead } from '@vueuse/head'

require('focus-visible')
const app = createApp(App)
const head = createHead()
app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY
  }
})
app.use(router)
app.use(head)
app.directive('trap-focus', trapFocus)
app.mount('#app')
