<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="#C2002F"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 24C30.63 24 36 18.63 36 12C36 5.37 30.63 0 24 0C17.37 0 12 5.37 12 12C12 18.63 17.37 24 24 24ZM24 30C15.99 30 0 34.02 0 42V48H48V42C48 34.02 32.01 30 24 30Z"
    />
  </svg>
</template>
