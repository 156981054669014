import router from '@/router'
import { ref } from 'vue'

const baseTitle = 'Expressway'
const title = ref(baseTitle)
const setTitle = (customTitle?: string | undefined) => {
  title.value = customTitle ? `${customTitle} | ${baseTitle}` : baseTitle
}

export default function useTitle () {
  router.beforeEach(route => {
    setTitle(route.name?.toString())
  })

  return { title, setTitle }
}
