
import { defineComponent } from 'vue'
import CheckLine from '@/components/vectors/CheckLine.vue'
import ErrorCircleIcon from '@/components/vectors/ErrorCircleIcon.vue'

export default defineComponent({
  name: 'ButtonConfirmation',
  props: {
    type: {
      type: Boolean,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    }
  },
  components: {
    CheckLine,
    ErrorCircleIcon
  }
})
