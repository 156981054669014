import { RouteLocationNormalized } from 'vue-router'

export const realTimeRoutes = [
  {
    path: '/rtpi',
    name: 'Rtpi',
    props: ({ params }: RouteLocationNormalized) => {
      if (typeof params.props !== 'string') return {}

      return { ...JSON.parse(params.props) }
    },
    component: () =>
      import(/* webpackChunkName: "realTime" */ '../views/Rtpi.vue')
  },
  {
    path: '/rtpi-near-me',
    name: 'rtpi near me',
    component: () =>
      import(/* webpackChunkName: "realTime" */ '../views/RtpiNearMe.vue')
  },
  {
    path: '/rtpi/:stopId',
    props: true,
    name: 'rtpi Departures List',
    component: () =>
      import(/* webpackChunkName: "realTime" */ '../views/RtpiDeparturesList.vue')
  },
  {
    path: '/track/:date/:serviceNumber/:time/:direction/:stopId',
    props: true,
    name: 'Track Departure',
    component: () =>
      import(/* webpackChunkName: "realTime" */ '../views/StopsSearchTrackRoute.vue')
  },
  {
    path: '/live-track/:date/:serviceNumber/:time/:direction/:stopId',
    name: 'Track Stops Map',
    props: true,
    component: () =>
      import(/* webpackChunkName: "realTime" */ '../views/StopsSearchMap.vue')
  }
]
