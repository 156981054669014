<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="29"
      height="29"
      rx="14.5"
      fill="#262626"
      stroke="white"
    />
    <path
      d="M15 14.5C17.0719 14.5 18.75 12.8219 18.75 10.75C18.75 8.67812 17.0719 7 15 7C12.9281 7 11.25 8.67812 11.25 10.75C11.25 12.8219 12.9281 14.5 15 14.5ZM15 16.375C12.4969 16.375 7.5 17.6313 7.5 20.125V22H22.5V20.125C22.5 17.6313 17.5031 16.375 15 16.375Z"
      fill="white"
    />
  </svg>
</template>
