
import { defineComponent } from 'vue'
import GenericError from '@/components/GenericError.vue'
import PageHeader from '@/components/PageHeader.vue'
import { useHead } from '@vueuse/head'

export default defineComponent({
  components: { GenericError, PageHeader },
  setup () {
    useHead({
      meta: [
        {
          id: 'prerender-status-code',
          name: 'prerender-status-code',
          content: '404'
        }
      ]
    })
  }

})
