export const redirections = [
  {
    path: '/airport-services',
    redirect: '/'
  },
  {
    path: '/home',
    redirect: '/'
  },
  {
    path: '/index',
    redirect: '/'
  },
  {
    path: '/cookie-list',
    redirect: '/cookie-policy'
  },
  {
    path: '/ballina-dublin-airport-bus',
    redirect: '/dublin-airport-services'
  },
  {
    path: '/dublinairport',
    redirect: '/dublin-airport-services'
  },
  {
    path: '/galway-dublin-airport-bus',
    redirect: '/dublin-airport-services'
  },
  {
    path: '/letterkenny-dublin-airport-bus',
    redirect: '/dublin-airport-services'
  },
  {
    path: '/Route-22-dublin-airport-to-ballina',
    redirect: '/dublin-airport-services'
  },
  {
    path: '/Route-23-dublin-airport-to-sligo-it',
    redirect: '/dublin-airport-services'
  },
  {
    path: '/routes/airport/ballina-dublin-airport-bus.php',
    redirect: '/dublin-airport-services'
  },
  {
    path: '/routes/airport/dublinairport.php',
    redirect: '/dublin-airport-services'
  },
  {
    path: '/waterford-dublin-airport-bus',
    redirect: '/dublin-airport-services'
  },
  {
    path: '/wexford-dublin-airport-bus',
    redirect: '/dublin-airport-services'
  },
  {
    path: '/grouptravel',
    redirect: '/group-travel'
  },
  {
    path: '/grouptravel',
    redirect: '/dublin-airport-services'
  },
  {
    path: '/manage-booking',
    redirect: '/search-tickets'
  },
  {
    path: '/shannonairport',
    redirect: '/shannon-airport-services'
  }
]
