
import { defineComponent } from 'vue'
import ExpresswayLogo from '@/components/vectors/ExpresswayLogo.vue'
import MyExpresswayLogo from '@/components/vectors/MyExpresswayLogo.vue'
import Person from '@/components/vectors/Person.vue'
import Avatar from '@/components/vectors/Avatar.vue'
import Navbar from '@/components/Navbars/Navbar.vue'
import useSessions from '@/composables/useSessions'

export default defineComponent({
  components: { ExpresswayLogo, MyExpresswayLogo, Avatar, Navbar, Person },
  setup () {
    const { isLoggedIn } = useSessions()
    return { isLoggedIn }
  }
})
