<template>
  <svg
    width="75"
    height="74"
    viewBox="0 0 75 74"
    fill="#9D9D9D"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M37.4625 0.306641C17.2428 0.306641 0.869141 16.6731 0.869141 36.839C0.869141 57.0049 17.2428 73.3714 37.4625 73.3714C57.719 73.3714 74.1292 57.0049 74.1292 36.839C74.1292 16.6731 57.719 0.306641 37.4625 0.306641ZM41.1623 18.5727H33.8363V40.4921H41.1623V18.5727ZM41.1623 47.7985H33.8363V55.105H41.1623V47.7985ZM8.1949 36.8389C8.1949 52.9862 21.3085 66.0648 37.4989 66.0648C53.6894 66.0648 66.803 52.9862 66.803 36.8389C66.803 20.6916 53.6894 7.61299 37.4989 7.61299C21.3085 7.61299 8.1949 20.6916 8.1949 36.8389Z"
    />
  </svg>
</template>
