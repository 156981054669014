const newsSubpath = '/service-news'

export const mobileRoutes = [
  {
    path: '/mobile/:slug',
    name: 'Mobile Content Page',
    props: true,
    meta: {
      showFooter: false,
      showHeader: false,
      mobileAppView: true
    },
    component: () =>
      import('../views/GenericPageMobile.vue')
  },
  {
    path: `/mobile/${newsSubpath}/:slug`,
    name: 'Mobile News',
    props: true,
    meta: {
      showFooter: false,
      showHeader: false,
      mobileAppView: true
    },
    component: () =>
      import(/* webpackChunkName: "news" */ '@/views/news/ShowMobile.vue')
  }
]
