<template>
  <svg
    width="11"
    height="22"
    viewBox="0 0 11 22"
    fill="#C2002F"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.298 16.265c0-1.684-.006-3.367.006-5.05.002-.283-.077-.372-.357-.36-.52.02-1.039-.002-1.558.009-.21.006-.287-.053-.285-.279.01-1.05.009-2.1 0-3.15-.001-.203.059-.269.26-.265.532.012 1.064-.015 1.594.011.307.015.37-.095.365-.383-.02-.8-.02-1.6-.001-2.4.02-.976.28-1.887.855-2.681C3.904.713 4.937.254 6.131.2 7.357.145 8.585.175 9.811.162c.172-.002.223.06.223.228a256.418 256.418 0 000 3.186c.002.19-.059.248-.242.244-.59-.01-1.18-.005-1.77-.003-.786.003-1.256.412-1.304 1.198-.04.63-.01 1.264-.026 1.897-.005.225.127.21.275.21.896 0 1.794.009 2.69-.006.264-.005.35.057.32.336-.113 1.055-.21 2.112-.299 3.17-.02.24-.128.285-.34.281a92.268 92.268 0 00-2.124-.003c-.509.003-.459-.075-.459.45-.003 3.294-.006 6.589.008 9.883.002.348-.087.434-.427.427a93.14 93.14 0 00-3.647.001c-.342.007-.398-.11-.395-.419.01-1.66.004-3.319.004-4.977z"
    />
  </svg>
</template>
