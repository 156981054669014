import { useRouter } from 'vue-router'
import useSwrv from 'swrv/dist/use-swrv'
import { getSubmenus } from '@/kentico-api/submenus.api'
import { computed, ref } from 'vue'

type Submenu = 'plan' | 'services'

export default function useNavMenu () {
  const router = useRouter()
  const { data: submenuResponse, error } = useSwrv(
    '/submenus',
    () => getSubmenus(),
    {
      revalidateOnFocus: false,
      refreshInterval: 0
    }
  )
  const submenus = computed(() => submenuResponse.value?.data)
  const activeSubmenu = ref<Submenu>()
  const isActiveSubmenu = (submenu: Submenu) => activeSubmenu.value === submenu
  const setActiveSubmenu = (submenu: Submenu) => {
    activeSubmenu.value = isActiveSubmenu(submenu) ? undefined : submenu
  }

  router.beforeEach(() => {
    activeSubmenu.value = undefined
  })

  return {
    activeSubmenu,
    isActiveSubmenu,
    setActiveSubmenu,
    error,
    submenus
  }
}
