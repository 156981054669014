<template>
  <svg fill="#fff" viewBox="0 0 156 19" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M97.707 14.212c-1.756-.043-3.21-.315-3.99-.744a.12.12 0 01-.041-.042l.861-2.229a.13.13 0 01.027-.056c.876.346 2.337.743 3.586.743 1.15 0 1.321-.436 1.321-.694 0-.45-.425-.76-1.569-1.146-1.226-.437-2.857-1.193-2.857-2.697 0-2.166 2.184-3.565 5.566-3.565 1.254 0 2.429.177 3.222.482.012 0 .047.019.01.114l-.748 1.955c-.026.077-.088.183-.14.183a.069.069 0 01-.028 0c-.702-.262-1.624-.406-2.597-.406-1.151-.002-1.392.376-1.392.694 0 .241.183.578 1.614 1.086 1.04.354 2.977 1.01 2.977 2.74 0 2.213-2.172 3.588-5.664 3.588l-.158-.006zM107.37 14.212c-1.754-.043-3.209-.315-3.99-.744a.12.12 0 01-.041-.042l.861-2.229a.122.122 0 01.027-.056c.877.346 2.337.743 3.586.743 1.151 0 1.327-.436 1.327-.694 0-.45-.426-.76-1.568-1.146-1.228-.437-2.859-1.194-2.859-2.697 0-2.166 2.185-3.565 5.566-3.565 1.255 0 2.43.177 3.223.482.011 0 .046.019.01.114l-.748 1.956c-.026.076-.09.182-.14.182a.069.069 0 01-.029 0c-.703-.262-1.624-.405-2.597-.405-1.15 0-1.391.377-1.391.695 0 .241.183.578 1.614 1.087 1.042.353 2.978 1.009 2.978 2.74 0 2.212-2.172 3.588-5.664 3.588l-.165-.009zM83.288 3.803h.451c.028 0 .056.01.079.026 0 0 0 .022-.017.065l-1.237 2.65"
    />
    <path
      d="M83.767 3.809h-.453c-1.796 0-2.954.394-3.76 1.224l-.21.216.012-.302a.854.854 0 00-.242-.654c-.435-.452-1.36-.483-1.633-.483H75.692a.144.144 0 00-.158.137.144.144 0 00.085.132h.017c.09.041.418.362.165 1.626-.086.424-.328 1.57-1.086 4.662l-.936 3.76a.053.053 0 000 .035c.02.014.044.02.068.017h3.392c.013 0 .056 0 .068-.078l1.015-3.682c.756-3.14 2.717-3.84 4.259-3.864L83.824 3.9c.019-.043.017-.064.015-.065a.133.133 0 00-.072-.025zM87.987 14.213c-3.646 0-5.655-1.57-5.655-4.431 0-2.909 2.594-6 7.401-6 2.14 0 4.637.845 4.637 3.211 0 2.182-2.369 3.242-7.24 3.242-.271 0-.549 0-.835-.01h-.165l.056.157c.1.266.273.5.5.673.507.371 1.306.56 2.37.56 1.35 0 2.5-.19 3.518-.58a.12.12 0 01.046-.01c.011 0 .022 0 .03.051l.203 2.19a.11.11 0 01-.058.132c-1.904.756-4.161.815-4.808.815zm1.2-8.234c-1.7 0-2.481 1.1-2.596 1.69l-.027.137h.14c2.875 0 3.798-.229 3.798-.941v-.022c0-.34-.484-.864-1.314-.864zM139.15 14.156h3.409s-.677-.278-.862-.892c.046-1.13.173-2.61.465-3.765l1.327-5.235a17.39 17.39 0 00-3.9-.452c-6.013 0-9.089 3.645-9.089 7.07 0 2.02 1.509 3.421 3.819 3.421 1.64 0 3.337-.64 4.609-2.474h.08c-.111.708-.121 1.459-.156 2.013a.277.277 0 00.069.233.267.267 0 00.229.08zm.198-5.744c-.611 2.558-2.361 3.96-3.794 3.96-1.35 0-1.852-.866-1.852-1.917 0-2.294 2.202-4.783 5.068-4.783.417-.007.834.027 1.245.101l-.667 2.64zM131.93 3.816l-3.85-.012a.148.148 0 00-.11.039.143.143 0 00-.046.106.16.16 0 00.099.145c.12.054.63.476.143 1.62l-2.453 6.188c.035-.63-.757-6.932-.89-7.969-.019-.155-.146-.146-.146-.146h-2.811a.346.346 0 00-.268.146l-3.777 7.969-.241-7.961a.147.147 0 00-.01-.055.141.141 0 00-.033-.046.129.129 0 00-.103-.033H114.315a.158.158 0 00-.137.14s-.013.091.104.142c.38.284.483.27.592 1.629l.491 8.13a.312.312 0 00.327.313h3.295l3.861-7.29h.053c0 .938.39 5.704.493 6.977a.326.326 0 00.328.313h3.635s4.706-10.154 4.706-10.164a.196.196 0 00.009-.048.125.125 0 00-.04-.1.131.131 0 00-.102-.033z"
    />
    <path
      d="M.195 14.115l5.254.015a.2.2 0 00.213-.197.224.224 0 00-.135-.197c-.165-.076-.86-.65-.196-2.212l3.346-8.442c-.047.858 1.036 9.457 1.215 10.88a.195.195 0 00.2.198h3.83a.467.467 0 00.361-.198l5.153-10.88.329 10.866a.18.18 0 00.203.179H24.233a.214.214 0 00.186-.192s.018-.121-.141-.193c-.52-.387-.66-.363-.808-2.223L22.794.427A.426.426 0 0022.348 0h-4.492l-5.272 9.946h-.073c0-1.28-.531-7.783-.673-9.52A.44.44 0 0011.39 0h-4.96S.017 13.853.012 13.866a.232.232 0 00-.013.066.175.175 0 00.197.182z"
      fill="#C9C6C6"
    />
    <path
      d="M70.144 3.805c-1.584 0-3.097.589-4.13 1.65l-.278.284.083-.388c.079-.362.033-.656-.159-.888-.466-.564-1.29-.66-1.86-.66h-1.832a.143.143 0 00-.158.14.154.154 0 00.09.132s.54.207.202 1.639c-.01.047-1.262 5.153-1.876 7.784l-.133.534v.023l-.029.121 1.507 2.368a.318.318 0 010 .269.29.29 0 01-.241.139h-1.87l-.338 1.764s.01.01.05.01h3.598c.037 0 .058-.021.067-.077l.999-3.676.352-1.207h.162l.021.01c.414.186 1.288.405 2.39.405 4.67 0 7.458-3.39 7.458-6.665 0-2.264-1.592-3.711-4.075-3.711zm-3.433 8.035c-.744 0-1.36-.175-1.833-.522l-.06-.044.43-1.951c.482-2.006 1.84-3.353 3.378-3.353 1.327 0 1.8.918 1.8 1.78-.003 2.031-1.585 4.09-3.715 4.09zM55.756 3.804H55.4a.145.145 0 00-.16.14.133.133 0 00.078.122h.017c.063.027.5.362.195.684-.452.472-.876.923-.876.923l1.851 2.844 4.636-4.709-5.385-.004zM144.92 5.714l1.876 7.567a.492.492 0 01-.055.466c-.569.845-1.147 1.57-2 2.052-.697.433-1.512.698-2.074.869a.158.158 0 00-.108.148.178.178 0 000 .033l.806 1.831c.045.12.076.12.151.12a.178.178 0 00.063-.012c.831-.168 2.347-.706 3.714-1.748 1.852-1.41 3.084-3.172 4.614-5.668l4.061-7.334a.15.15 0 00.027-.082.16.16 0 00-.16-.16h-3.668a.16.16 0 00-.16.16.156.156 0 00.086.139s.516.47-.052 1.644v.01l-1.626 3.103c-.593 1.122-.903 1.818-1.217 2.453h-.054c-.104-.697-.259-1.46-.483-2.58l-.951-4.77a.104.104 0 00-.014-.029.154.154 0 00-.055-.086.154.154 0 00-.096-.035h-3.333a.162.162 0 00-.113.048.164.164 0 00-.046.113.154.154 0 00.086.138s.354.122.781 1.61z"
    />
    <path
      d="M25.515 5.715l1.874 7.567a.483.483 0 01-.054.466c-.57.845-1.148 1.57-2.001 2.053-.696.432-1.512.697-2.074.868a.157.157 0 00-.108.148.226.226 0 00.006.033l.806 1.831c.045.121.077.121.151.121a.178.178 0 00.063-.013c.831-.168 2.348-.705 3.714-1.747 1.852-1.412 3.084-3.173 4.614-5.669l4.061-7.333a.148.148 0 00.027-.083.16.16 0 00-.156-.16H32.77a.16.16 0 00-.16.16.159.159 0 00.085.138s.517.47-.051 1.645v.01l-1.635 3.103c-.594 1.122-.904 1.819-1.218 2.454h-.054c-.104-.698-.258-1.46-.489-2.58l-.95-4.77a.106.106 0 00-.015-.03.155.155 0 00-.15-.12h-3.326a.16.16 0 00-.16.16.156.156 0 00.087.14s.354.12.781 1.609z"
      fill="#C9C6C6"
    />
    <path
      d="M60.487 16.113l-.979-1.601-6.087-9.671c-.766-1.05-1.723-1.028-1.723-1.028l-3.336.008a.145.145 0 00-.145.145.144.144 0 00.078.12c.354.249.543.464 1.393 1.633.227.311 1.648 2.55 1.956 3.095l-2.03 2.117-.648.671h-7.44c-.447 0-.328-.325-.328-.325l.672-2.696 3.468.008.688-2.605L42.5 5.97l.746-3.019c.08-.323.315-.312.315-.312h4.686L48.935.07H37.044a.155.155 0 00-.154.153.151.151 0 00.073.128c.591.433 2.172 1.207 1.313 5.183l-1.923 8.09-.028.12c-.095.397.322.425.322.425h12.987c.588.011 1.16-.187 1.616-.56.222-.209.523-.534.835-.883l1.267-1.267c1.643 2.71 1.643 2.71 2.628 4.26.407.642.405.643 1.404.643h2.972a.161.161 0 00.16-.16.158.158 0 00-.029-.09z"
    />
  </svg>
</template>
