import { reactive, watchEffect } from 'vue'
import packageJson from './../../package.json'

const verifyLocalStorageVersion = () => {
  if (
    typeof localStorage.APP_STORAGE_VERSION === 'undefined' ||
    localStorage.APP_STORAGE_VERSION === null
  ) {
    localStorage.setItem('APP_STORAGE_VERSION', packageJson.localStorageVersion)
  } else if (localStorage.APP_STORAGE_VERSION !== packageJson.localStorageVersion) {
    localStorage.clear()
    localStorage.setItem('APP_STORAGE_VERSION', packageJson.localStorageVersion)
  }
}
export default function useLocalStorage <T extends Record<string, unknown>>
(key: string, defaultValue: T): T {
  verifyLocalStorageVersion()

  const setStorage = (state: T) =>
    localStorage.setItem(key, JSON.stringify(state))

  const getStorage = (): T | undefined => {
    const jsonString = localStorage.getItem(key)
    if (!jsonString) return
    return { ...defaultValue, ...JSON.parse(jsonString) }
  }

  const reactiveState = reactive(
    getStorage() || defaultValue
  ) as T

  watchEffect(() => setStorage(reactiveState))

  return reactiveState
}
