<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="#456FB4"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.4997 0.0834961C4.74967 0.0834961 0.0830078 4.75016 0.0830078 10.5002C0.0830078 16.2502 4.74967 20.9168 10.4997 20.9168C16.2497 20.9168 20.9163 16.2502 20.9163 10.5002C20.9163 4.75016 16.2497 0.0834961 10.4997 0.0834961ZM11.5418 5.2915H9.4585V7.37484H11.5418V5.2915ZM11.5418 9.4578H9.4585V15.7078H11.5418V9.4578ZM2.1665 10.4999C2.1665 15.0937 5.90609 18.8333 10.4998 18.8333C15.0936 18.8333 18.8332 15.0937 18.8332 10.4999C18.8332 5.90617 15.0936 2.16659 10.4998 2.16659C5.90609 2.16659 2.1665 5.90617 2.1665 10.4999Z"
    />
  </svg>
</template>
