export const amendRoutes = [
  {
    path: '/amend-booking/:bookingId',
    name: 'AmendBooking',
    meta: { showFooter: true },
    component: () =>
      import(/* webpackChunkName: "amends" */ '../views/AmendBooking.vue')
  },
  {
    path: '/amend-booking-settings/:bookingId/:amendId',
    name: 'AmendBookingSettings',
    meta: { showFooter: false },
    component: () =>
      import(/* webpackChunkName: "amends" */ '../views/AmendBookingSettings.vue')
  },
  {
    path: '/amend-booking-dates/:bookingId/:amendId',
    name: 'AmendBookingDates',
    meta: { showFooter: false },
    component: () =>
      import(/* webpackChunkName: "amends" */ '../views/AmendBookingDates.vue')
  },
  {
    path: '/amend-booking-outbound/:bookingId/:amendId',
    name: 'AmendBookingOutbound',
    meta: { showFooter: false },
    props: { section: 'outbound' },
    component: () =>
      import(/* webpackChunkName: "amends" */ '../views/AmendBookingJourney.vue')
  },
  {
    path: '/amend-booking-return/:bookingId/:amendId',
    name: 'AmendBookingReturn',
    meta: { showFooter: false },
    props: { section: 'return' },
    component: () =>
      import(/* webpackChunkName: "amends" */ '../views/AmendBookingJourney.vue')
  },
  {
    path: '/amend-booking-resume/:bookingId/:amendId',
    name: 'AmendBookingResume',
    meta: { showFooter: false },
    component: () =>
      import(/* webpackChunkName: "amends" */ '../views/AmendBookingResume.vue')
  },
  {
    path: '/booking-refund/:bookingId',
    name: 'BookingRefund',
    meta: { showFooter: false },
    component: () =>
      import(/* webpackChunkName: "amends" */ '../views/RefundBooking.vue')
  },
  {
    path: '/amend-booking-payment/:bookingId/:amendId',
    name: 'AmendBookingPayment',
    meta: { showFooter: false },
    component: () =>
      import(/* webpackChunkName: "amends" */ '../views/AmendBookingPayment.vue')
  },
  {
    path: '/amend-booking-success/:bookingId/:amendId',
    name: 'AmendBookingSuccess',
    meta: { showFooter: false },
    component: () =>
      import(/* webpackChunkName: "amends" */ '../views/AmendBookingSuccess.vue')
  }
]
