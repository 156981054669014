
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    heading: {
      type: String,
      required: true
    },
    subheading: String
  }
})
