import { onUnmounted } from 'vue'

const keyPrefix = 'TIMEOUT_'

const setCache = (key: string, timestamp: number) =>
  localStorage.setItem(keyPrefix + key, timestamp.toString())

const getCache = (key: string) => Number(localStorage.getItem(keyPrefix + key))

export default function useTimeout (key: string, callback: () => void) {
  let timerId: number | undefined

  const setTimerFromCache = () => {
    const cachedTimeout = getCache(key) - Date.now()
    if (cachedTimeout > 0) timerId = setTimeout(callback, cachedTimeout)
  }

  const setTimer = (timeout: number) => {
    clearTimeout(timerId)
    timerId = setTimeout(callback, timeout)
    setCache(key, Date.now() + timeout)
  }

  const clearTimer = () => {
    clearTimeout(timerId)
    localStorage.removeItem(keyPrefix + key)
  }

  setTimerFromCache()

  window.addEventListener('storage', setTimerFromCache)

  onUnmounted(() => {
    window.removeEventListener('storage', setTimerFromCache)
  })

  return {
    setTimer,
    clearTimer
  }
}
