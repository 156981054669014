import { BusStop } from '@/models/BusStop'
import { ref } from 'vue'
import { getAllBusStops } from '@/expressway-api/busStops.api'

const stops = ref<BusStop[]>([])
const loadingStops = ref<boolean>(false)
const errorLoadingStops = ref<boolean>(false)

export function useStops () {
  function updateStops (newStops: BusStop[] | undefined) {
    if (newStops !== undefined) {
      stops.value = newStops
    }
  }

  async function loadStops () {
    try {
      loadingStops.value = true
      stops.value = await getAllBusStops()
      loadingStops.value = false
    } catch (error) {
      stops.value = []
      loadingStops.value = false
      throw error
    }
  }

  return {
    stops,
    updateStops,
    loadStops,
    loadingStops,
    errorLoadingStops
  }
}
