const destinationSubpath = '/destinations'

export const planRoutes = [
  {
    path: `${destinationSubpath}`,
    name: 'Destinations',
    component: () =>
      import(
        /* webpackChunkName: "destinations" */ '@/views/destinations/Index.vue'
      )
  },
  {
    path: `${destinationSubpath}/:slug`,
    name: 'Destination',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "destinations" */ '@/views/destinations/Show.vue'
      )
  }
]
