const camelize = (string: string) =>
  string
    .replace(/[\s,_,-]+(\w)/g, (_match, p) => p.toUpperCase())
    .replace(/^([A-Z])/, (_match, p) => p.toLowerCase())

function camelizeKeys<T> (input: T): T {
  if (typeof input !== 'object' || !input) return input

  if (Array.isArray(input)) return (input.map(camelizeKeys) as unknown) as T

  return (Object.keys(input).reduce(
    (newObject: { [x: string]: unknown }, key) => {
      const value = ((input as unknown) as { [x: string]: unknown })[key]
      newObject[camelize(key)] = camelizeKeys(value)
      return newObject
    },
    {}
  ) as unknown) as T
}

export { camelize, camelizeKeys }
