
import { computed } from '@vue/reactivity'
import { defineComponent, ref } from 'vue'
import VRuntimeTemplate from 'vue3-runtime-template'
import CkeditorModule from '@/components/CkeditorModule.vue'
import BookingFormComponent from '@/components/BookingFormComponent.vue'

const camelize = (string: string) => {
  const result = string
    .replace(/[\s,_,-]+(\w)/g, (_match, p) => p.toUpperCase())
    .replace(/^([A-Z])/, (_match, p) => p.toLowerCase())
  return result[0].toUpperCase() + result.substring(1)
}

// interface BodyCkeditor {
//   value: string
// }

interface System {
  id: string;
  name: string;
  codename: string;
  language: string;
  type: string;
  collection: string;
  sitemapLocations: unknown[];
  lastModified: string;
  workflow: string;
  workflowStep: string;
}

interface Element {
  type: string;
  name: string;
  value: unknown;
}

interface Elements {
  [key: string]: Element;
}

interface ContentComponent {
  system: System;
  elements: Elements;
}

interface ModularContent {
  [key: string]: ContentComponent;
}

export default defineComponent({
  name: 'RichTextRender',
  components: {
    VRuntimeTemplate,
    // eslint-disable-next-line vue/no-unused-components
    ckeditormodule: CkeditorModule,
    // eslint-disable-next-line vue/no-unused-components
    bookingformcomponent: BookingFormComponent
  },
  props: {
    html: {
      type: String,
      required: true
    },
    linkedItems: {
      type: Object as () => ModularContent,
      required: true
    }
  },
  setup (props) {
    const title = ref('Kentico Title')
    const data = ref()

    // eslint-disable-next-line complexity
    const htmlConverted = computed(() => {
      const replaces: { new: string; old: string }[] = []
      const doc = document.createElement('div')
      doc.innerHTML = props.html
      const links = doc.getElementsByTagName('object')
      for (let i = 0; i < links.length; i++) {
        // console.log(i, links[i].dataset.codename)
        try {
          if (links[i] && links[i].dataset.codename) {
            const linkedItem = resolveKenticoLinkedItems(
              links[i].dataset.codename as string
            )
            const newItem = document.createElement('div')
            if (linkedItem.componentType === 'PageSection') {
              newItem.innerHTML =
                `<${linkedItem.componentType}
                  codename="${linkedItem.codename}"
                  id="${linkedItem.id}"
                  body="${linkedItem.bodyCkeditor?.value || ''}" />`
            } else if (linkedItem.componentType === 'CkeditorBlock') {
              newItem.innerHTML =
                `<ckeditormodule
                  codename="${linkedItem.codename}"
                  html="${encodeURIComponent(linkedItem.elements?.body?.value || '')}" />`
            } else {
              newItem.innerHTML =
                `<${linkedItem.componentType}
                  codename="${linkedItem.codename}"
                  encodeddata="${encodeURIComponent(JSON.stringify(linkedItem.elements))}"
                />`
            }
            // console.log(newItem.innerHTML, links[i].outerHTML)
            replaces.push({
              new: newItem.innerHTML,
              old: links[i].outerHTML
            })
            // links[i].parentNode?.replaceChild(newItem, links[i])
          }
        } catch (error) {
        }
      }
      replaces.forEach(item => {
        doc.innerHTML = doc.innerHTML.replace(item.old, item.new)
      })
      return doc.innerHTML
    })

    const resolveKenticoLinkedItems = (codename: string) => {
      let resolvedItem = {} as any
      if (props.linkedItems) {
        const found =
          Object.values(props.linkedItems).find((item: any) => item.system.codename === codename)
        if (found) {
          resolvedItem = {
            componentType: camelize(found.system.type),
            codename: found.system.codename,
            ...found
          }
        }
      }
      return resolvedItem
    }
    return {
      htmlConverted,
      title,
      data
    }
  }
})
