<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <circle cx="10" cy="10" r="10" fill="#262626" />
    <path
      d="M9.48 6.36a1.18 1.18 0 100-2.36 1.18 1.18 0 000 2.36zM11.31 13.07a2.62 2.62 0 11-3.56-3.12v-1.1a3.66 3.66 0 104.37 5.02l-.42-.8h-.39z"
      fill="#fff"
    />
    <path
      d="M14.76 14.47l-1.59-3.11a.7.7 0 00-.61-.38h-2.03v-.47h1.74c.18 0 .32-.09.42-.22a.5.5 0 00.1-.3.52.52 0 00-.52-.52h-1.75V7.9c0-.43-.31-1.04-1.03-1.04-.58 0-1.05.47-1.05 1.04v3.4c0 .59.48 1.07 1.07 1.07h2.62l1.4 2.73a.7.7 0 101.23-.63z"
      fill="#fff"
    />
  </svg>
</template>
