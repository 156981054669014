
import { defineComponent, PropType } from 'vue'
import AlertIcon from '@/components/vectors/AlertIcon.vue'
import SuccessIcon from '@/components/vectors/SuccessIcon.vue'

export default defineComponent({
  emits: ['close'],
  props: {
    message: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String as PropType<'danger' | 'success'>,
      default: 'danger'
    },
    showDismissButton: {
      type: Boolean,
      required: false,
      default: true
    },
    spill: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: { AlertIcon, SuccessIcon }
})
