import { httpClient } from './httpClient'
import {
  BusStop,
  RTPIBusStop,
  NavigatorLocation
} from '@/models/BusStop'
import {
  RTPIDeparture
} from '@/models/RtpiDeparture'

const getAllBusStops = (): Promise<BusStop[]> =>
  httpClient
    .get<{ BusStops: BusStop[] }>('/departure-list')
    .then(response => response.data.BusStops)

const getRTPIBusStops = (): Promise<RTPIBusStop[]> =>
  httpClient
    .get('/rtpi/all-stops')
    .then(response => response.data)

const getRTPIBusStopById = (stopId: number): Promise<RTPIBusStop> => httpClient
  .get('/rtpi/all-stops')
  .then(response => response.data.find(
    (stop: RTPIBusStop) => stop.UTrackId === stopId.toString()))

const getRTPIDeparturesByStop = (
  stopId: number,
  fromEpoch: string,
  toEpoch: string
): Promise<RTPIDeparture[]> =>
  httpClient
    .get<{ Departures: RTPIDeparture[] }>(`/departures/${stopId}/${fromEpoch}/${toEpoch}`)
    .then(response => response.data.Departures)

const getDepartureDetailByStop = (
  date: string,
  serviceNumber: string,
  time: string,
  direction: string,
  stopIds: string
): Promise<RTPIDeparture> =>
  httpClient
    .get(`/departure/${date}/${serviceNumber}/${time}/${direction}/${stopIds}`) // eslint-disable-line max-len
    .then(response => response.data)

const fetchRTPINearByStops = (currentLocation: NavigatorLocation):
  Promise<RTPIBusStop[]> =>
  httpClient
    .post('/rtpi/nearby-stops', currentLocation)
    .then(response => response.data)

export {
  fetchRTPINearByStops,
  getAllBusStops,
  getRTPIBusStops,
  getRTPIBusStopById,
  getRTPIDeparturesByStop,
  getDepartureDetailByStop
}
