<template>
  <svg
    width="25"
    height="21"
    viewBox="0 0 25 21"
    fill="#C2002F"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.621 2.89114C23.7302 3.29538 22.7729 3.56942 21.7685 3.69218C22.7935 3.06226 23.5814 2.06529 23.9517 0.877372C22.992 1.46017 21.9294 1.88425 20.7979 2.11242C19.8926 1.12289 18.6025 0.505371 17.1744 0.505371C14.4321 0.505371 12.2089 2.78326 12.2089 5.5931C12.2089 5.99238 12.2525 6.38051 12.3372 6.75251C8.21032 6.54047 4.55061 4.51554 2.10111 1.43661C1.67391 2.18806 1.42823 3.06226 1.42823 3.99474C1.42823 5.7605 2.30443 7.31671 3.63689 8.22935C2.82241 8.20331 2.05755 7.97391 1.38708 7.59323C1.38708 7.61431 1.38708 7.63539 1.38708 7.65771C1.38708 10.1228 3.09834 12.1788 5.36993 12.6462C4.95361 12.7628 4.5143 12.8248 4.06168 12.8248C3.74218 12.8248 3.43115 12.7926 3.12738 12.733C3.75912 14.7542 5.59382 16.2261 7.76617 16.267C6.06702 17.6323 3.92492 18.4457 1.59887 18.4457C1.19829 18.4457 0.802544 18.4222 0.414062 18.3751C2.61183 19.8184 5.22228 20.6616 8.02637 20.6616C17.1599 20.6616 22.1557 12.9091 22.1557 6.18459C22.1557 5.96386 22.1509 5.74438 22.1412 5.52614C23.113 4.80818 23.9553 3.91166 24.621 2.89114Z"
    />
  </svg>
</template>
