
import { computed, defineComponent, ref, watch } from 'vue'
import Select from '@/components/Select.vue'
import { useStops } from '@/composables/useStops'
import { BusStop } from '@/models/BusStop'
import JourneyPassengerSelect from '@/components/JourneyPassengerSelect.vue'
import router from '@/router'
import { urlEncodeFares } from '@/helpers/fares'
import Spinner from '@/components/Spinner.vue'
import Checkbox from '@/components/Checkbox.vue'
import { formatDate as altFormatDate } from '@/helpers'
export default defineComponent({
  name: 'BookingFormComponent',
  components: {
    Select,
    JourneyPassengerSelect,
    Spinner,
    Checkbox
  },
  props: {
    encodeddata: {
      type: String,
      required: true
    }
  },
  // eslint-disable-next-line max-lines-per-function, complexity
  setup (props) {
    try {
      const { stops, errorLoadingStops: error, loadingStops } = useStops()
      const expandPassengers = ref(false)
      const wheelchairPassenger = ref(false)
      const filters = ref(
        {
          departureDate: '',
          returnDate: '',
          departureStop: '',
          destinationStop: '',
          oneWay: true,
          passengers: {
            adult: 1,
            child: 0,
            youngAdult: 0,
            student: 0,
            dsp: 0,
            seatReservation: 0
          }
        }
      )
      const decodedData = JSON.parse(decodeURIComponent(props.encodeddata || ''))

      const loadingData = computed(() => !departureStopsOptions.value)

      const departureStopsOptions = computed(() => {
        if (!stops.value) return []

        if (decodedData.outwardOriginStopIds?.value.split(',').length === 0) {
          return []
        }

        const options = decodedData.outwardOriginStopIds?.value
          .replace(/\s+/g, '')
          .split(',').map((stop: any) => {
            const stopData =
              stops.value?.find((fullStop: BusStop) => Number(fullStop.BusStopId) === Number(stop))
            return {
              value: stop,
              name: stopData?.BusStopName || stop
            }
          }).filter((stop: any) => stop.value && stop.value !== '')

        return [
          {
            value: '',
            name: 'Select Departure'
          },
          ...options
        ]
      })

      const destinationStopsOptions = computed(() => {
        if (!stops.value) return []

        if (decodedData.outwardDestinationStopIds.value.split(',').length === 0) {
          return []
        }
        const options = decodedData.outwardDestinationStopIds.value
          .replace(/\s+/g, '').split(',').map((stop: any) => {
            const stopData =
              stops.value?.find((fullStop: BusStop) => Number(fullStop.BusStopId) === Number(stop))
            return {
              value: stop,
              name: stopData?.BusStopName || stop
            }
          }).filter((stop: any) => stop.value && stop.value !== '')

        return [
          {
            value: '',
            name: 'Select Destination'
          },
          ...options
        ]
      })

      const outwardDatesOptions = computed(() => {
        if (!decodedData.outwardDates) return []

        const options = decodedData.outwardDates.value
          .replace(/\s+/g, '').split(',').map((date: any) =>
            ({
              value: date,
              name: altFormatDate(date)
            })
          )
          .filter((date: any) => {
            const isValidFormat = /^\d{4}-\d{2}-\d{2}$/.test(date.value)
            return isValidFormat && new Date(date.value) >= new Date()
          })

        if (options.length > 4) {
          // add select return date - empty value at the beginning
          options.unshift({
            value: '',
            name: 'Select Outward Date'
          })
        }
        return options
      })

      const returnDatesOptions = computed(() => {
        if (!decodedData.returnDates) return []

        let options = decodedData.returnDates.value
          .replace(/\s+/g, '')
          .split(',').map((date: any) =>
            ({
              value: date,
              name: altFormatDate(date)
            })
          )
          .filter((date: any) => {
            const isValidFormat = /^\d{4}-\d{2}-\d{2}$/.test(date.value)
            return isValidFormat && new Date(date.value) >= new Date()
          })

        if (filters.value.departureDate !== '') {
          // filter dates before departure date
          options =
            options.filter(
              (date: any) => new Date(date.value) >= new Date(filters.value.departureDate))
        }

        if (options.length > 4) {
          // add select return date - empty value at the beginning
          options.unshift({
            value: '',
            name: 'Select Return Date'
          })
        }
        return options
      })

      const [today] = new Date().toISOString().split('T')
      filters.value.departureDate =
        outwardDatesOptions.value.find(date => date.value >= today)?.value || ''
      filters.value.returnDate =
        returnDatesOptions.value.find(date => date.value >= today)?.value || ''

      if (decodedData.outwardOriginStopIds.value.split(',').length === 1) {
        [filters.value.departureStop] = decodedData.outwardOriginStopIds.value.split(',')
      }
      if (decodedData.outwardDestinationStopIds.value.split(',').length === 1) {
        [filters.value.destinationStop] = decodedData.outwardDestinationStopIds.value.split(',')
      }
      // eslint-disable-next-line complexity
      const searchJourneys = () => {
        if (!formReady.value) return
        // sendExponeaCheckoutEvent(
        //   {
        //     step: 'journey-search',
        //     basket_code: '',
        //     journey_origin_stop: stopState.origin.stop?.BusStopName,
        //     journey_destination_stop: stopState.destination.stop?.BusStopName,
        //     origin_stop: stopState.origin.stop?.BusStopName,
        //     destination_stop: stopState.destination.stop?.BusStopName,
        //     departure_date: dataset.departureDate,
        //     return_journey: !dataset.oneWay,
        //     origin_stop_country: 'Ireland',
        //     origin_stop_code: stopState.origin.stop?.BusStopId.toString() || '',
        //     origin_stop_city: stopState.origin.stop?.CityName,
        //     destination_stop_country: 'Ireland',
        //     destination_stop_city: stopState.destination.stop?.CityName,
        //     destination_stop_code: stopState.destination.stop?.BusStopId.toString() || '',
        //     Timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        //     bus_company: 'Bus Éireann',
        //     line_brand: 'Expressway',
        //     currency: 'EUR',
        //     quantity: passengersCount.value
        //   }
        // )
        const query = {
          originId: filters.value.departureStop.toString() || '',
          destinationId: filters.value.destinationStop.toString() || '',
          departureDate: filters.value.departureDate,
          returnDate: !filters.value.oneWay || returnOnly.value ? filters.value.returnDate : '',
          promoCode: decodedData.promocode?.value || '',
          oneWay: returnOnly.value ? 'false' : filters.value.oneWay.toString(),
          fares: urlEncodeFares(filters.value.passengers),
          wheelchair: 'false',
          wheelchairPassengerFareClass: ''
        }
        // console.log('query', query, filters.value.returnDate)
        router.push({
          name: 'Select Journey',
          query
        })
      }
      // watch departure date selected and update return date to be after departure date
      watch(() => filters.value.departureDate, (newVal: string) => {
        if (newVal && new Date(filters.value.returnDate) < new Date(newVal)) {
          filters.value.returnDate = newVal
        }
      })
      const filteredPassengerTypes = computed(() => (
        decodedData.filterPassengerTypes.value.map((passengerType: any) =>
          passengerType.codename.replace(/_([a-z])/g, (g: any) => g[1].toUpperCase()))
      ))

      const returnAllowed = computed(() =>
        decodedData.allowReturns?.value[0]?.codename === 'yes' || false)

      const returnOnly = computed(() =>
        decodedData.allowReturns?.value[0]?.codename === 'return_only' || false)

      // eslint-disable-next-line complexity
      const formReady = computed(() => {
        if (!filters.value.departureStop || !filters.value.destinationStop) return false
        if (!filters.value.departureDate) return false
        if (!filters.value.oneWay && !filters.value.returnDate) return false

        if (new Date(filters.value.departureDate) < new Date()) return false

        // compare outward with return dates
        if (
          (!filters.value.oneWay || returnOnly.value) &&
          new Date(filters.value.departureDate) > new Date(filters.value.returnDate)) return false
        // verify if filter.passengers has at least one passenger
        const searchPassengers = Object.values(filters.value.passengers)
          .reduce((acc, val) => acc + val, 0)
        if (searchPassengers === 0) return false

        return true
      })

      // eslint-disable-next-line complexity
      const errorForm = computed(() => {
        if (
          (!filters.value.oneWay || returnOnly.value) &&
          new Date(filters.value.departureDate) > new Date(filters.value.returnDate)) {
          return 'Return date must be after departure date'
        }

        if (new Date(filters.value.departureDate) < new Date()) {
          return 'Departure date must be in the future'
        }

        const searchPassengers = Object.values(filters.value.passengers)
          .reduce((acc, val) => acc + val, 0)
        if (searchPassengers === 0) return 'Please select at least one passenger'

        return ''
      })

      return {
        errorForm,
        formReady,
        returnAllowed,
        returnOnly,
        loadingStops,
        decodedData,
        filters,
        departureStopsOptions,
        destinationStopsOptions,
        outwardDatesOptions,
        returnDatesOptions,
        stops,
        error,
        loadingData,
        expandPassengers,
        searchJourneys,
        filteredPassengerTypes,
        wheelchairPassenger,
        altFormatDate
      }
    } catch (error) {
      return {
        error: true,
        loadingData: false,
        loadingStops: false,
        destinationStopsOptions: [],
        departureStopsOptions: [],
        outwardDatesOptions: [],
        returnDatesOptions: [],
        stops: [],
        filters: {
          departureDate: '',
          returnDate: '',
          departureStop: '',
          destinationStop: '',
          oneWay: true,
          passengers: {
            adult: 1,
            child: 0,
            youngAdult: 0,
            student: 0,
            dsp: 0,
            seatReservation: 0
          }
        },
        expandPassengers: false,
        wheelchairPassenger: false,
        filteredPassengerTypes: [],
        altFormatDate: () => ''
      }
    }
  }
})
